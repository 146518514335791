import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { assignmentReducer } from 'features/assignments/assignments.slice';
import { evaluationReducer } from 'features/evaluations/evaluation.slice';
import { kpiDetailsReducer } from 'features/kpiDetails/kpiDetails.slice';
import { pendingEvaluationsReducer } from 'features/pendingEvaluations/pendingEvaluations.slice';
import { periodsReducer } from 'features/periods/period.slice';
import { resumeReducer } from 'features/resume/resume.slice';
import { sessionReducer } from 'features/session/sessionSlice';
import { summaryReducer } from 'features/summary/summary.slice';
import { toastReducer } from 'features/toast/toastSlice';
import { upcomingEvaluationReducer } from 'features/upcomingEvaluation/upcomingEvaluation.slice';
import { userManagementReducer } from 'features/userManagement/userManagementSlice';
import { userProfileReducer } from 'features/userProfile/userProfile.slice';

import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
  reducer: {
    assignment: assignmentReducer,
    evaluations: evaluationReducer,
    counter: counterReducer,
    periods: periodsReducer,
    kpiDetails: kpiDetailsReducer,
    resume: resumeReducer,
    session: sessionReducer,
    userManagement: userManagementReducer,
    toast: toastReducer,
    summary: summaryReducer,
    userProfile: userProfileReducer,
    upcomingEvaluation: upcomingEvaluationReducer,
    pendingEvaluations: pendingEvaluationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
