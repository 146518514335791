import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import style from './KTooltip.module.scss';

interface KLayoutProps {
  children: React.ReactElement;
  position?: Array<number>;
  title: string;
}

function KTooltip({ children, position, title }: KLayoutProps) {
  return (
    <Tooltip
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: position,
              },
            },
          ],
        },
      }}
      classes={{
        popper: style.tooltipPopper,
      }}
      placement='top-start'
      title={title}
    >
      <IconButton className={style.iconSVG} disableRipple>
        {children}
      </IconButton>
    </Tooltip>
  );
}

export default KTooltip;
