import React, { Fragment, ReactElement } from 'react';
import { Card, Typography } from '@mui/material';

import styles from './KUpcomingReminder.module.scss';

interface KUpcomingReminderProps {
  endDate: string;
  endLabel?: string;
  noUpcomingLabel?: string;
  startDate: string;
  startLabel?: string;
  upcomingLabel?: string;
}

const KUpcomingReminder = ({
  endDate,
  endLabel = 'Ends:',
  noUpcomingLabel = 'No upcoming evaluation',
  startDate,
  startLabel = 'Starts:',
  upcomingLabel = 'Upcoming evaluation',
}: KUpcomingReminderProps): ReactElement => (
  <Card className={styles.upcomingCard}>
    <Typography component='p' variant='subtitle1'>
      {upcomingLabel}
    </Typography>
    {startDate ? (
      <Fragment>
        <Typography component='p' variant='body1'>
          {startLabel} {startDate}
        </Typography>
        <Typography component='p' variant='body1'>
          {endLabel} {endDate}
        </Typography>
      </Fragment>
    ) : (
      <Typography component='p' variant='body1'>
        {noUpcomingLabel}
      </Typography>
    )}
  </Card>
);

export default KUpcomingReminder;
