import React, { Fragment, useState } from 'react';
import { Button, Typography } from '@mui/material';

import styles from './KReadMore.module.scss';

interface KReadMoreProps {
  maxLength: number;
  text: string;
}

const KReadMore = ({ text, maxLength }: KReadMoreProps) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const commentText = isReadMore ? `${text.slice(0, 200)}...` : text;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Fragment>
      {text.length > maxLength ? (
        <Fragment>
          <Typography className={styles.text} component='p' variant='body1'>
            {commentText}
          </Typography>
          <Button
            className={styles.viewButton}
            variant='text'
            onClick={toggleReadMore}
          >
            {isReadMore ? 'View more' : 'View less'}
          </Button>
        </Fragment>
      ) : (
        <Typography className={styles.text} component='p' variant='body1'>
          {text}
        </Typography>
      )}
    </Fragment>
  );
};

export default KReadMore;
