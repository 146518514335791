import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectIsAuth } from 'features/session/sessionSelectors';

import { MAIN_ROUTES } from './Roots';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProtectedRoute = ({ children, ...props }: any) => {
  const isAuthenticated = useAppSelector(selectIsAuth);

  return isAuthenticated ? (
    <Route {...props}>{children}</Route>
  ) : (
    <Redirect to={MAIN_ROUTES.LOGIN} />
  );
};
