export const evaluationsCompanyTabs = [
  {
    label: 'Overview',
    value: 'overview',
  },
  {
    label: 'Progress',
    value: 'progress',
  },
  {
    label: 'KPIs',
    value: 'kpis',
  },
];
