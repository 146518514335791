import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { initSession } from 'features/session/sessionActions';
import {
  selectIsSessionConfirmed,
  selectLogInStatus,
} from 'features/session/sessionSelectors';
import { Toast } from 'features/toast/Toast';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { MAIN_ROUTES } from 'routes/Roots';

import Login from 'components/KLoginLayout/KLoginLayout';
import EvaluationsRouter from 'modules/Evaluations';
import ResultsRouter from 'modules/Results';
import SummaryRouter from 'modules/Summary';

function App() {
  const dispatch = useDispatch();
  const hasSessionBeenChecked = useSelector(selectIsSessionConfirmed);
  const sessionStatus = useSelector(selectLogInStatus);

  useEffect(() => {
    if (!hasSessionBeenChecked) {
      dispatch(initSession());
    }
  }, [hasSessionBeenChecked, dispatch]);

  return (
    <Fragment>
      {sessionStatus !== 'initial' &&
        (process.env.REACT_APP_DEVELOPMENT_MODE === 'true' ? (
          <Switch>
            <Route component={Login} path={MAIN_ROUTES.LOGIN} />
            <Route component={SummaryRouter} exact path={MAIN_ROUTES.SUMMARY} />
            <Route
              component={EvaluationsRouter}
              path={MAIN_ROUTES.EVALUATIONS}
            />
            <Route component={ResultsRouter} path={MAIN_ROUTES.RESULTS} />
          </Switch>
        ) : (
          <Switch>
            <Route component={Login} path={MAIN_ROUTES.LOGIN} />
            <ProtectedRoute
              component={SummaryRouter}
              exact
              path={MAIN_ROUTES.SUMMARY}
            />
            <ProtectedRoute
              component={EvaluationsRouter}
              path={MAIN_ROUTES.EVALUATIONS}
            />
            <ProtectedRoute
              component={ResultsRouter}
              path={MAIN_ROUTES.RESULTS}
            />
          </Switch>
        ))}
      <Toast />
    </Fragment>
  );
}

export default App;
