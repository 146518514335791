import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';

import KTableHeader from 'components/KTable/components/KTableHeader/KTableHeader';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import {
  MainTableColumn,
  MainTableRow,
  SortingOptions,
} from 'utils/table.utils';

import KTableRow from './components/KTableRow/KTableRow';

interface KTableProps {
  columns: MainTableColumn[];
  footerRow?: MainTableRow;
  isLoading?: boolean;
  noRowsMessage?: string;
  rows: MainTableRow[];
  sortingOptions?: SortingOptions;
  onClickSort: (sortingValues: SortingOptions) => void;
}

function KTable({
  columns,
  footerRow,
  isLoading = false,
  noRowsMessage = "We couldn't find any result that matched your criteria. Please try again.",
  rows,
  sortingOptions,
  onClickSort,
}: KTableProps) {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (rows.length <= 0) {
    return (
      <Box
        alignItems='center'
        display='flex'
        justifyContent='center'
        padding='64px'
        textAlign='center'
      >
        <Typography component='p' variant='h5'>
          {noRowsMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <KTableHeader
          columns={columns}
          sortingOptions={sortingOptions}
          onClickSort={onClickSort}
        />
        <TableBody>
          {rows.map((row, index) => (
            <KTableRow
              isLastRow={index === rows.length - 1}
              key={row.id}
              rowData={row}
            />
          ))}
          {footerRow && <KTableRow rowData={footerRow} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default KTable;
