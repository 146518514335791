import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import {
  selectIsLoadingKpiDetails,
  selectKpiDetails,
} from 'features/kpiDetails/kpiDetails.selectors';
import {
  selectIsLoadingPeriod,
  selectPeriods,
} from 'features/periods/period.selectors';
import { getQuarters } from 'features/periods/periods.actions';
import {
  selectIsLoadingResume,
  selectResume,
} from 'features/resume/resume.selectors';
import { KpiDetailsData } from 'models/kpiDetails.model';
import { v4 as uuid } from 'uuid';

import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';
import KAccordion from 'components/KAccordion';
import KLayout from 'components/KLayout';
import KpiResultsDetails from 'components/KpiResultsDetails';
import KQuarterTabs from 'components/KQuarterTabs/KQuarterTabs';
import KResumeKPI from 'components/KResumeKPI/KResumeKPI';
import { dataTransform } from 'components/KResumeKPI/resultCardData.utils';
import KTooltip from 'components/KTooltip/KTooltip';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

function ResultsView(): ReactElement {
  const dispatch = useDispatch();
  const periods = useSelector(selectPeriods);
  const kpiDetails = useSelector(selectKpiDetails);
  const resumeResults = useSelector(selectResume);
  const isKpiDetailsLoading = useSelector(selectIsLoadingKpiDetails);
  const isResumeLoading = useSelector(selectIsLoadingResume);
  const isPeriodsLoading = useSelector(selectIsLoadingPeriod);

  useEffect(() => {
    dispatch(getQuarters());
  }, [dispatch]);

  return (
    <KLayout>
      <Typography component='h1' variant='h5'>
        Results
      </Typography>
      <Box sx={{ marginBottom: '24px' }}>
        {isPeriodsLoading ? (
          <LoadingSpinner />
        ) : (
          <KQuarterTabs period={periods} />
        )}
      </Box>
      <Grid columnSpacing={24} container rowSpacing={24}>
        <Grid item xs={12}>
          {isResumeLoading ? (
            <LoadingSpinner />
          ) : (
            <KResumeKPI results={dataTransform(resumeResults)} />
          )}
        </Grid>
        <Grid item sx={{ display: 'flex' }} xs={12}>
          <Typography component='h2' variant='subtitle2'>
            KPIs
          </Typography>
          <KTooltip
            position={[-50, -8]}
            title='KPIs that impact the business have a higher value.'
          >
            <InfoIcon />
          </KTooltip>
        </Grid>
        <Grid item xs={12}>
          {isKpiDetailsLoading ? (
            <LoadingSpinner />
          ) : (
            kpiDetails.map(
              ({
                maxScore,
                group,
                myScore,
                name,
                description,
                scores,
              }: KpiDetailsData) => (
                <KAccordion
                  key={`accordion-${uuid()}`}
                  maxScore={maxScore}
                  position={group}
                  score={myScore}
                  title={name}
                >
                  <KpiResultsDetails
                    description={description}
                    group={group}
                    maxScore={maxScore}
                    myScore={myScore}
                    name={name}
                    scores={scores}
                  />
                </KAccordion>
              ),
            )
          )}
        </Grid>
      </Grid>
    </KLayout>
  );
}

export default ResultsView;
