export const COMPANY_FILTERS_SECTIONS = {
  status: {
    label: 'Status',
    options: [
      { label: 'Active', param: 'active' },
      { label: 'Draft', param: 'draft' },
      { label: 'Expired', param: 'expired' },
    ],
  },
  department: {
    label: 'Department',
    options: [
      { label: 'Cybersecurity', param: '1' },
      { label: 'HR', param: '2' },
      { label: 'IT & OPS', param: '3' },
      { label: 'PMO', param: '4' },
      { label: 'Product Design', param: '5' },
      { label: 'Recruitment', param: '6' },
    ],
  },
};
