import React from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import { Status } from 'utils/table.utils';

import textStyles from '../KTableCell/KTableCell.module.scss';
import styles from './StatusCell.module.scss';

interface StatusCellProps {
  status: string;
}

function StatusCell({ status }: StatusCellProps) {
  const dotStyle = clsx({
    [styles.dot]: true,
    [styles.inProgress]: status === Status.InProgress,
    [styles.new]: status === Status.New,
    [styles.expired]: status === Status.Expired,
    [styles.completed]: status === Status.Completed,
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span className={dotStyle} />
       <Typography
        className={textStyles.tableCellText}
        component='p'
        variant='body1'
       >
        {status}
       </Typography>
    </Box>
  );
}

export default StatusCell;
