import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { KDropdownProps } from './KComboBox.types';
import { useDebounce } from './KComboBox.utils';

export default function KComboBox({
  initialValue = '',
  label,
  options,
  onSelect,
}: KDropdownProps) {
  const [inputValue, setInputValue] = useState(initialValue);
  const debouncedValue = useDebounce<string>(inputValue, 1000);
  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().indexOf(debouncedValue.toLowerCase()) > -1,
  );

  React.useEffect(() => {
    onSelect(inputValue);
  }, [onSelect, inputValue]);

  return (
    <Autocomplete
      filterOptions={(x) => x}
      options={filteredOptions}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params) => <TextField {...params} label={label} />}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
    />
  );
}
