const kpiDetails = [
  {
    period: 'Q1',
    year: 2022,
    name: 'Kpi Name',
    description: 'Kpi description',
    scores: [
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 6,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 10,
        maxScore: 15,
        comment: null,
      },
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
    ],
    myScore: 40,
    maxScore: 45,
    group: 'top',
  },
  {
    period: 'Q1',
    year: 2022,
    name: 'Level of preparedness/courses & certifications',
    description:
      'Description lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et. Description lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et',
    scores: [
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
    ],
    myScore: 20,
    maxScore: 45,
    group: 'bottom',
  },
  {
    period: 'Q2',
    year: 2022,
    name: 'Kpi Name',
    description: 'Kpi description',
    scores: [
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
      {
        score: 10,
        maxScore: 15,
        comment: 'This is a comment',
      },
    ],
    myScore: 40,
    maxScore: 45,
    group: 'bottom',
  },
];

const periods = [
  {
    id: 1,
    periodName: 'Q1',
    isActive: true,
  },
  {
    id: 2,
    periodName: 'Q2',
    isActive: true,
  },
  {
    id: 3,
    periodName: 'Q3',
    isActive: false,
  },
  {
    id: 4,
    periodName: 'Q4',
    isActive: false,
  },
];

const resumeResults = [
  {
    roundedAveragePercentage: 65,
    roundedCompanyAveragePercentage: 34,
    roundedPeersAveragePercentage: 70,
    period: 'Q1',
    year: 2022,
  },
  {
    roundedAveragePercentage: 68,
    roundedCompanyAveragePercentage: 78,
    roundedPeersAveragePercentage: 74,
    period: 'Q2',
    year: 2022,
  },
];

export { kpiDetails, periods, resumeResults };
