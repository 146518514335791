import React from 'react';
import { Card, CardMedia, Grid, Typography } from '@mui/material';

import InstructionsCardImage from 'assets/images/instructionsCardImage.svg';

import styles from './KInstructionsCard.module.scss';

interface KInstructionsCardProps {
  employeeToBeReviewed: string;
}

function KInstructionsCard({ employeeToBeReviewed }: KInstructionsCardProps) {
  return (
    <Card className={styles.cardRoot} elevation={0}>
      <Grid columns={10} container justifyContent='center'>
        <Grid item xs={1}>
          <CardMedia
            alt='Instructions Picture'
            className={styles.cardMedia}
            component='img'
            image={InstructionsCardImage}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography className={styles.titleText} component='h3' variant='h3'>
            Evaluation Instructions
          </Typography>
          <Typography
            className={styles.collaboratorText}
            component='p'
            variant='body1'
          >
            {`Collaborator to be evaluated: ${employeeToBeReviewed}`}
          </Typography>
          <Typography
            className={styles.descriptionText}
            component='p'
            variant='body1'
          >
            1. Read each KPI below and evaluate them according to their
            performance.
            <br /> 2. The scale may change according to the KPI’s impact on the
            business.
            <br />
            3. Please read carefully, and remember this evaluation is anonymous,
            keep it honest.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default KInstructionsCard;
