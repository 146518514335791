import React, { useState } from 'react';
import { Divider } from '@mui/material';
import { FilterSectionsModel } from 'models/filtersSectionsModel';

import FiltersCard from '../components/Card';
import CheckboxFilters from '../components/Sections/CheckboxFilters';

interface NewEvaluationFormFiltersProps {
  sections: Record<string, FilterSectionsModel>;
  onClickApplyFilters: (queryParams: {
    evaluators: string[];
    evaluatorsAssigned: string[];
  }) => void;
}

function NewEvaluationFormFilters({
  sections,
  onClickApplyFilters,
}: NewEvaluationFormFiltersProps) {
  const { evaluators, evaluatorsAssigned } = sections;
  const [evaluatorsFilters, setEvaluatorsFilters] = useState<string[]>([]);
  const [evaluatorsAssignedFilters, setEvaluatorsAssignedFilters] = useState<
    string[]
  >([]);

  const handleChangeEvaluatorsAssignedFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (evaluatorsAssignedFilters.includes(event.target.name)) {
      const newFiltersValue = evaluatorsAssignedFilters.filter(
        (evaluatorsAssigned) => evaluatorsAssigned !== event.target.name,
      );
      return setEvaluatorsAssignedFilters(newFiltersValue);
    }

    return setEvaluatorsAssignedFilters([
      ...evaluatorsAssignedFilters,
      event.target.name,
    ]);
  };

  const handleChangeEvaluatorsFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (evaluatorsFilters.includes(event.target.name)) {
      const newFiltersValue = evaluatorsFilters.filter(
        (evaluators) => evaluators !== event.target.name,
      );
      return setEvaluatorsFilters(newFiltersValue);
    }

    return setEvaluatorsFilters([...evaluatorsFilters, event.target.name]);
  };

  const handleClearFilters = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setEvaluatorsAssignedFilters([]);
    setEvaluatorsFilters([]);
  };

  const handleClickApply = (_event: React.MouseEvent<HTMLButtonElement>) => {
    const queryParams = {
      evaluators: evaluatorsFilters,
      evaluatorsAssigned: evaluatorsAssignedFilters,
    };
    onClickApplyFilters(queryParams);
  };

  return (
    <FiltersCard
      onClickApply={handleClickApply}
      onClickClear={handleClearFilters}
    >
      <CheckboxFilters
        filters={evaluatorsAssignedFilters}
        section={evaluatorsAssigned}
        onChange={handleChangeEvaluatorsAssignedFilter}
      />
      <Divider sx={{ marginBottom: '24px' }} />
      <CheckboxFilters
        filters={evaluatorsFilters}
        section={evaluators}
        onChange={handleChangeEvaluatorsFilter}
      />
    </FiltersCard>
  );
}

export default NewEvaluationFormFilters;
