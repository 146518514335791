import { MainTableColumn } from 'utils/table.utils';

export const mainTableHeaderColumns: MainTableColumn[] = [
  {
    field: 'toBeEvaluated',
    headerName: 'To be evaluated',
    hasSorting: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    hasSorting: true,
  },
  {
    field: 'project',
    headerName: 'Project',
    hasSorting: true,
  },
  {
    field: 'period',
    headerName: 'Period',
    hasSorting: true,
  },
  {
    field: 'year',
    headerName: 'Year',
    hasSorting: true,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    hasSorting: true,
  },
  {
    field: 'link',
  },
];
