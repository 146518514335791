import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';

interface LinkCellProps {
  to: string;
}

function LinkCell({ to }: LinkCellProps) {
  return (
    <Link to={to}>
      <Box
        sx={{
          width: '20px',
          height: '20px',
        }}
      >
        <ArrowForwardIosIcon color='primary' fontSize='small' />
      </Box>
    </Link>
  );
}

export default LinkCell;
