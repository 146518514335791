import React, { ReactElement } from 'react';
import { Box, Card, Typography } from '@mui/material';

import styles from './KEvaluationOverview.module.scss';

interface KEvaluationOverviewProps {
  assignmentsMissing: number;
  author: string;
  averageTotal: number;
  completedCount: number;
  department: string;
  endDate: string;
  evaluationsCount: number;
  maxTotal: number;
  quarter: string;
  startDate: string;
  status: string;
  year: number;
}

const KEvaluationOverview = ({
  assignmentsMissing,
  author,
  averageTotal,
  completedCount,
  department,
  endDate,
  evaluationsCount,
  maxTotal,
  quarter,
  startDate,
  status,
  year,
}: KEvaluationOverviewProps): ReactElement => (
  <Card className={styles.infoCard}>
    <Box color='grey.700' display='flex' flexDirection='column' rowGap={8}>
      <Typography component='p' variant='subtitle1'>
        {department}
      </Typography>
      <Typography component='p' variant='body1'>
        <b>
          {completedCount}/{evaluationsCount}
        </b>{' '}
        Evaluations completed
      </Typography>
      <Typography component='p' variant='body1'>
        <b>
          {averageTotal}/{maxTotal}
        </b>{' '}
        Average total points
      </Typography>
      <Typography component='p' variant='body1'>
        Author: {author}
      </Typography>
      <Typography component='p' variant='body1'>
        {quarter} {year}
      </Typography>
      <Typography component='p' variant='body1'>
        {startDate} - {endDate}
      </Typography>
      {assignmentsMissing > 0 ? (
        <Typography color='warning.main' component='p' variant='body1'>
          {assignmentsMissing} assigments missing
        </Typography>
      ) : (
        <Typography component='p' variant='body1'>
          All evaluators assigned
        </Typography>
      )}
    </Box>
    {status === 'active' ? (
      <Typography color='success.main' component='p' variant='body1'>
        Active
      </Typography>
    ) : (
      <Typography color='warning.main' component='p' variant='body1'>
        Draft
      </Typography>
    )}
  </Card>
);

export default KEvaluationOverview;
