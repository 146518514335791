export const evaluations = [
  {
    id: 1,
    department: 'Product Design',
    completedCount: 7,
    evaluationsCount: 16,
    averageTotal: 35,
    maxTotal: 40,
    author: 'Nour Maatouk',
    quarter: 'Q4',
    year: 2022,
    startDate: '00/00/0000',
    endDate: '00/00/0000',
    assignmentsMissing: 0,
    status: 'active',
  },
  {
    id: 2,
    department: 'PMO',
    completedCount: 0,
    evaluationsCount: 16,
    averageTotal: 0,
    maxTotal: 40,
    author: 'Peter Parker',
    quarter: 'Q4',
    year: 2022,
    startDate: '00/00/0000',
    endDate: '00/00/0000',
    assignmentsMissing: 0,
    status: 'active',
  },
  {
    id: 3,
    department: 'Cybersecurity',
    completedCount: 0,
    evaluationsCount: 16,
    averageTotal: 0,
    maxTotal: 40,
    author: 'Peter Parker',
    quarter: 'Q4',
    year: 2022,
    startDate: '00/00/0000',
    endDate: '00/00/0000',
    assignmentsMissing: 3,
    status: 'draft',
  },
  {
    id: 4,
    department: 'Recruitment',
    completedCount: 7,
    evaluationsCount: 16,
    averageTotal: 35,
    maxTotal: 40,
    author: 'Nour Maatouk',
    quarter: 'Q4',
    year: 2022,
    startDate: '00/00/0000',
    endDate: '00/00/0000',
    assignmentsMissing: 0,
    status: 'active',
  },
  {
    id: 5,
    department: 'IT & OPS',
    completedCount: 0,
    evaluationsCount: 16,
    averageTotal: 0,
    maxTotal: 40,
    author: 'Peter Parker',
    quarter: 'Q4',
    year: 2022,
    startDate: '00/00/0000',
    endDate: '00/00/0000',
    assignmentsMissing: 0,
    status: 'active',
  },
  {
    id: 6,
    department: 'HR',
    completedCount: 0,
    evaluationsCount: 16,
    averageTotal: 0,
    maxTotal: 40,
    author: 'Peter Parker',
    quarter: 'Q4',
    year: 2022,
    startDate: '00/00/0000',
    endDate: '00/00/0000',
    assignmentsMissing: 0,
    status: 'active',
  },
];
