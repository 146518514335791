import { AppThunk } from 'app/store';

import { describeApiError } from 'utils/errorUtils';

import { setToast } from '../toast/toastSlice';

import { fetchKpiDetailsByPeriod } from './kpiDetails.api';
import { setIsLoadingKpiDetails, setKpiDetails } from './kpiDetails.slice';

export const getKpiDetails =
  (periodRequested: string, yearRequested: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoadingKpiDetails(true));
    try {
      const { data } = await fetchKpiDetailsByPeriod(
        periodRequested,
        yearRequested,
      );
      dispatch(setKpiDetails(data));
    } catch (error) {
      dispatch(
        setToast({
          message: describeApiError(error),
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingKpiDetails(false));
    }
  };
