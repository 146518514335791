import { ResumeModel } from 'models/resumeModel';

import { resumeResults } from 'modules/Results/views/ResultsView/mockData';
import { ResponseData } from 'services/baseService';

// A mock function to mimic making an async request for data
export function fetchResume(periodRequested: string, yearRequested: number) {
  const resume = resumeResults.filter(
    ({ period, year }) =>
      period[1] === periodRequested && year === yearRequested,
  );
  const data = Object.assign({}, ...resume);

  return new Promise<ResponseData<ResumeModel>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
        }),
      500,
    ),
  );
}
