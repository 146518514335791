import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EvaluationModel } from 'models/evaluationModel';
import { QueryParamsModel } from 'models/queryParamsModel';

interface EvaluationState {
  count: number;
  evaluations: EvaluationModel[];
  isLoadingEvaluations: boolean;
  offset: number;
  queryParams: QueryParamsModel;
}

const initialState: EvaluationState = {
  count: 0,
  evaluations: [],
  isLoadingEvaluations: false,
  offset: 0,
  queryParams: {
    period: [],
    status: [],
  },
};

export const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setIsLoadingEvaluations: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEvaluations = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setEvaluations: (state, action: PayloadAction<EvaluationModel[]>) => {
      state.evaluations = action.payload;
    },
    setQueryParams: (state, action: PayloadAction<QueryParamsModel>) => {
      state.queryParams = action.payload;
    },
  },
});

export const {
  setOffset,
  setIsLoadingEvaluations,
  setCount,
  setEvaluations,
  setQueryParams,
} = evaluationSlice.actions;

export const evaluationReducer = evaluationSlice.reducer;
