import React from 'react';

export interface MainTableColumn {
  field: string;
  hasSorting?: boolean;
  headerName?: string;
}

export interface MainTableRow {
  id: string | number;
  innerCells: MainTableCell[];
}

export interface MainTableCell {
  value: string | React.ReactElement;
}

export enum Status {
  InProgress = 'In progress',
  New = 'New',
  Expired = 'Expired',
  Completed = 'Completed',
}

export type SortingOptions = [string, 'ASC' | 'DESC'][];
