import React, { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';

import KpiRanking from 'components/KpiRanking';

import styles from './KAccordion.module.scss';

interface KAccordionProps {
  children: ReactNode;
  maxScore: number | string;
  position: string;
  score: number | string;
  title: string;
}

function KAccordion({
  children,
  maxScore,
  position,
  score,
  title,
}: KAccordionProps) {
  return (
    <Accordion disableGutters elevation={0} sx={{ marginBottom: '16px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color='primary' />}
        sx={{ padding: '4px 29px 4px 24px', overflow: 'hidden' }}
      >
        <Box className={styles.contentContainer}>
          <KpiRanking maxScore={maxScore} position={position} score={score} />
          <Typography component='span' variant='subtitle1'>
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <Divider className={styles.divider} />
      <AccordionDetails className={styles.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default KAccordion;
