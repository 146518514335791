import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

import styles from './KEmptyState.module.scss';

interface KEmptyStateProps {
  image: ReactElement;
  title: string;
}

function KEmptyState({ title, image }: KEmptyStateProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {image}
      <Typography className={styles.emptySubtitle} component='h4' variant='h4'>
        {title}
      </Typography>
    </Box>
  );
}

export default KEmptyState;
