import React from 'react';
import { TableRow } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { MainTableRow } from 'utils/table.utils';

import KTableCell from '../KTableCell/KTableCell';

import styles from './KTableRow.module.scss';

interface KTableRowProps {
  isLastRow?: boolean;
  rowData: MainTableRow;
}

function KTableRow({ rowData, isLastRow }: KTableRowProps) {
  const { innerCells } = rowData;

  return (
    <TableRow className={isLastRow ? styles.lastRow : styles.row}>
      {innerCells.map((cell) => (
        <KTableCell cellData={cell} key={`${cell.value}-${uuid()}`} />
      ))}
    </TableRow>
  );
}

export default KTableRow;
