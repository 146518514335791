import { EvaluationModel } from 'models/evaluationModel';

import { ListResponseData, ServiceOptions } from 'services/baseService';

import { evaluations } from '../../modules/Evaluations/views/EvaluationsView/mockData';

// A mock function to mimic making an async request for data
export function fetchEvaluations(options: ServiceOptions) {
  const { offset = 0, limit = 0, where } = options;
  const { status }: { status: string[] } = where;
  const end = offset + limit;

  return new Promise<ListResponseData<EvaluationModel>>((resolve) =>
    setTimeout(() => {
      if (status.length !== 0) {
        const formattedFilters = status.map((filter) =>
          (filter.charAt(0).toUpperCase() + filter.slice(1)).replace('_', ' '),
        );
        const filteredData: EvaluationModel[] = evaluations.filter(
          (evaluation) => formattedFilters.includes(evaluation.status),
        );
        return resolve({
          message: 'OK',
          count: filteredData.length,
          limit,
          offset,
          data: filteredData,
        });
      }
      const data = evaluations.slice(offset, end);
      return resolve({
        message: 'OK',
        count: evaluations.length,
        limit,
        offset,
        data,
      });
    }, 200),
  );
}
