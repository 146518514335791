import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

import styles from 'components/KModal/KModal.module.scss';

interface KModalProps {
  actionLabel?: string;
  body: string;
  closeLabel?: string;
  onClickActionButton: React.MouseEventHandler<HTMLButtonElement>;
  onCloseModal: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  title: string;
}

function KModal({
  actionLabel = 'Submit',
  body,
  closeLabel = 'Cancel',
  title,
  open,
  onCloseModal,
  onClickActionButton,
}: KModalProps) {
  return (
    <React.Fragment>
      <Modal
        aria-describedby='modal-modal-description'
        aria-labelledby='modal-modal-title'
        className={styles.rootModal}
        open={open}
      >
        <Box className={styles.rootBox}>
          <Typography component='h4' id='modal-modal-title' variant='h4'>
            {title}
          </Typography>
          <Typography
            className={styles.bodyText}
            component='p'
            id='modal-modal-description'
            variant='body1'
          >
            {body}
          </Typography>
          <Box className={styles.actionBox}>
            <Button variant='outlined' onClick={onCloseModal}>
              {closeLabel}
            </Button>
            <Button variant='contained' onClick={onClickActionButton}>
              {actionLabel}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default KModal;
