import React, { ReactElement } from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';

import styles from './KEvaluationCounter.module.scss';

interface KEvaluationCounterProps {
  completedCount: number;
  pendingCount: number;
}

const KEvaluationCounter = ({
  completedCount,
  pendingCount,
}: KEvaluationCounterProps): ReactElement => {
  const pluralizeLabel = (count?: number) => (count !== 1 ? 's' : '');

  return (
    <Card className={styles.counterCard}>
      <Box className={styles.counterBoxLeft}>
        <Typography component='p' variant='h3'>
          {completedCount}
        </Typography>
        <Typography component='p' variant='body1'>
          Completed evaluation{pluralizeLabel(completedCount)}
        </Typography>
      </Box>
      <Divider flexItem orientation='vertical' variant='middle' />
      <Box className={styles.counterBoxRight}>
        <Typography component='p' variant='h3'>
          {pendingCount}
        </Typography>
        <Typography component='p' variant='body1'>
          Pending evaluation{pluralizeLabel(pendingCount)}
        </Typography>
      </Box>
    </Card>
  );
};

export default KEvaluationCounter;
