import React, { Fragment } from 'react';
import { Button, Grid, Typography } from '@mui/material';

import KSearchBar from '../KSearchBar/KSearchBar';

import styles from './KFormSearchGroup.module.scss';

interface KFormSearchGroupProps {
  filterTextButton?: string;
  isFilterButtonDisabled?: boolean;
  searchValue: string;
  title: string;
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function KFormSearchGroup({
  title,
  filterTextButton = 'Filter',
  searchValue,
  isFilterButtonDisabled,
  onChangeSearch,
  onClickFilter,
}: KFormSearchGroupProps) {
  return (
    <Fragment>
      <Typography className={styles.instruction} component='p' variant='body1'>
        {title}
      </Typography>
      <Grid columnSpacing='20px' columns={12} container>
        <Grid className={styles.itemSearch} item sm={10} xs={12}>
          <KSearchBar
            placeholder='Search by name'
            value={searchValue}
            onChange={onChangeSearch}
          />
        </Grid>
        <Grid className={styles.itemFilters} item sm={2} xs={12}>
          <Button
            disabled={isFilterButtonDisabled}
            variant='outlined'
            onClick={onClickFilter}
          >
            {filterTextButton}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default KFormSearchGroup;
