import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Box, Card, Container, IconButton, Typography } from '@mui/material';
import { selectIsAuth } from 'features/session/sessionSelectors';
import { updateIsAuthenticated } from 'features/session/sessionSlice';
import { MAIN_ROUTES } from 'routes/Roots';

import { ReactComponent as LoginImage } from 'assets/images/loginImage.svg';
import { ReactComponent as Mslogo } from 'assets/images/mslogo1.svg';
import styles from 'components/KLoginLayout/KLoginLayout.module.scss';

export default function KLogin() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(selectIsAuth);

  const handleClickLoginButton = () => {
    dispatch(updateIsAuthenticated(true));
  };

  if (isAuthenticated) {
    return <Redirect to={MAIN_ROUTES.SUMMARY} />;
  }

  return (
    <Box className={styles.backgroundBox}>
      <Container className={styles.cardContainer}>
        <Box>
          <LoginImage className={styles.loginImage} />
        </Box>
        <Card className={styles.cardContent}>
          <Typography
            className={styles.performanceAppText}
            component='h1'
            variant='h2'
          >
            Performance App
          </Typography>
          <Typography className={styles.logInText} component='h1' variant='h3'>
            Welcome!
          </Typography>
          <Typography
            className={styles.bodyText}
            component='p'
            variant='subtitle1'
          >
            To the best way to know about your performance.
          </Typography>
          <IconButton
            className={styles.buttonMSLogin}
            onClick={handleClickLoginButton}
          >
            <Mslogo />
          </IconButton>
        </Card>
      </Container>
    </Box>
  );
}
