import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import KNavBar from 'components/KNavBar';

import EvaluationFormView from './views/EvaluationFormView';
import EvaluationsCompanyView from './views/EvaluationsCompanyView';
import EvaluationsView from './views/EvaluationsView';
import EvaluatorsAssignation from './views/EvaluatorsAssignation';
import NewEvaluationFormView from './views/NewEvaluationFormView';

const EvaluationsRouter = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/company/new/:id`}>
        <Route
          component={EvaluatorsAssignation}
          exact
          path={`${path}/company/new/:id`}
        />
      </Route>
      <Route exact path={`${path}/company/new`}>
        <Route
          component={NewEvaluationFormView}
          exact
          path={`${path}/company/new`}
        />
      </Route>
      <Route exact path={`${path}/company`}>
        <KNavBar path={path} />
        <Route
          component={EvaluationsCompanyView}
          exact
          path={`${path}/company`}
        />
      </Route>
      <Route path={`${path}/:id`}>
        <Route component={EvaluationFormView} exact path={`${path}/:id`} />
      </Route>
      <Route path={path}>
        <KNavBar path={path} />
        <Route component={EvaluationsView} exact path={path} />
      </Route>
    </Switch>
  );
};

export default EvaluationsRouter;
