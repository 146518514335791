import { EvaluatorData } from './evaluatorsAssignation.utils';

const evaluators: EvaluatorData[] = [
  {
    id: 1,
    name: 'Daniel Alcudia',
    position: 'Self',
    commonProject: ['NA'],
    assigned: 2,
  },
  {
    id: 2,
    name: 'Ariane Ademar',
    position: 'Mentor ',
    commonProject: ['No common project'],
    assigned: 13,
  },
  {
    id: 3,
    name: 'Ulrich Feline',
    position: 'Manager',
    commonProject: ['11 Health'],
    assigned: 12,
  },
  {
    id: 4,
    name: 'Tase Norbert',
    position: 'Product Designer I',
    commonProject: ['Zaamna '],
    assigned: 13,
  },
  {
    id: 5,
    name: 'Don Julio',
    position: 'Product Designer III ',
    commonProject: ['Synkron'],
    assigned: 4,
  },
  {
    id: 6,
    name: 'Luisa Chang',
    position: 'IC2 Software Engineer',
    commonProject: ['Zaamna'],
    assigned: 3,
  },
  {
    id: 7,
    name: 'Ulrich Feline',
    position: 'Manager',
    commonProject: ['11 Health'],
    assigned: 12,
  },
  {
    id: 8,
    name: 'Tase Norbert',
    position: 'Product Designer I',
    commonProject: ['Zaamna'],
    assigned: 7,
  },
  {
    id: 9,
    name: 'Don Julio',
    position: 'Product Designer III ',
    commonProject: ['Synkron'],
    assigned: 4,
  },
  {
    id: 10,
    name: 'Luisa Chang',
    position: 'IC2 Software Engineer',
    commonProject: ['Zaamna'],
    assigned: 1,
  },
];

export { evaluators };
