export const NEW_EVALUATION_FORM_FILTERS_SECTIONS = {
  evaluatorsAssigned: {
    label: 'Evaluators Assigned',
    options: [
      { label: 'Unassigned', param: 'unassigned' },
      { label: 'More than one', param: 'more_than_one' },
      { label: 'More than two', param: 'more_than_two' },
      { label: 'More than three', param: 'more_than_three' },
      { label: 'All assigned', param: 'all_assigned' },
    ],
  },
  evaluators: {
    label: 'Evaluators',
    options: [
      { label: 'Manager', param: 'manager' },
      { label: 'Self', param: 'self' },
      { label: 'Peers', param: 'peers' },
      { label: 'External', param: 'external' },
    ],
  },
};
