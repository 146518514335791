import { ResumeModel } from 'models/resumeModel';

import { ResultCardData } from './resultCardData.types';

export const dataTransform = (resumeResults: ResumeModel) => {
  const resumeNames = ['My results', 'Company average', 'Peers average'];
  const results: ResultCardData[] = [];

  Object.entries(resumeResults).forEach((entry, index) => {
    const [, value] = entry;
    if (index < 3) {
      // TODO: errase this if once the resume endpoint is included
      results.push({
        id: index,
        text: resumeNames[index],
        value: `${value.toString()}%`,
      });
    }
  });

  return results;
};
