import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';

import './KCalendar.scss';
import styles from './KDatePicker.module.scss';

interface KDatePickerProps {
  date: Date | null;
  label: string;
  onChangeDate: (selectedDate: Date | null) => void;
}

function KDatePicker({ label, date, onChangeDate }: KDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        renderInput={(params) => (
          <TextField className={styles.datePicker} {...params} />
        )}
        value={date}
        onChange={onChangeDate}
      />
    </LocalizationProvider>
  );
}

export default KDatePicker;
