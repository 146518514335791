import React, { Fragment } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import KBackBar, { KBackBarProps } from 'components/KBackBar';

interface KEvaluationProgressBarProps extends KBackBarProps {
  progress: number;
}

function KEvaluationProgressBar({
  label,
  progress,
  onClickBack,
}: KEvaluationProgressBarProps) {
  return (
    <Fragment>
      <KBackBar label={label} onClickBack={onClickBack} />
      <LinearProgress value={progress} variant='determinate' />
    </Fragment>
  );
}

export default KEvaluationProgressBar;
