import { fetchPendingEvaluations } from 'services/summaryService';

import { AppThunk } from '../../app/store';
import { describeApiError } from '../../utils/errorUtils';
import { setToast } from '../toast/toastSlice';

import {
  setIsLoadingPendingEvaluations,
  setPendingEvaluationsCount,
} from './pendingEvaluations.slice';

export const getPendingEvaluationsCount = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoadingPendingEvaluations(true));
  try {
    const { data } = await fetchPendingEvaluations();
    dispatch(setPendingEvaluationsCount(data));
  } catch (error) {
    dispatch(
      setToast({
        message: describeApiError(error),
        isActive: true,
        type: 'error',
      }),
    );
  } finally {
    dispatch(setIsLoadingPendingEvaluations(false));
  }
};
