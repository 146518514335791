import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodProps } from 'models/periodsModel';

interface PeriodState {
  isLoadingPeriods: boolean;
  periods: PeriodProps[];
}

const initialState: PeriodState = {
  isLoadingPeriods: false,
  periods: [],
};

export const periodSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    setIsLoadingPeriods: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPeriods = action.payload;
    },
    setPeriods: (state, action: PayloadAction<PeriodProps[]>) => {
      state.periods = action.payload;
    },
  },
});

export const { setIsLoadingPeriods, setPeriods } = periodSlice.actions;

export const periodsReducer = periodSlice.reducer;
