import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KpiModel } from 'models/kpiModel';
import { KpiToSaveModel } from 'models/kpiToSaveModel';

interface AssignmentState {
  assignmentHasError: boolean;
  assignmentKpis: KpiModel[];
  completionPercentage: number;
  evaluateeName: string;
  isLoadingAssignment: boolean;
  kpiToSave: KpiToSaveModel;
}

const initialState: AssignmentState = {
  assignmentHasError: false,
  assignmentKpis: [],
  completionPercentage: 0,
  evaluateeName: '',
  isLoadingAssignment: true,
  kpiToSave: { comment: '', score: 0 },
};

export const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    setAssignmentHasError: (state, action: PayloadAction<boolean>) => {
      state.assignmentHasError = action.payload;
    },
    setAssignmentKpis: (state, action: PayloadAction<KpiModel[]>) => {
      state.assignmentKpis = action.payload;
    },
    setCompletionPercentage: (state, action: PayloadAction<number>) => {
      state.completionPercentage = action.payload;
    },
    setEvaluateeName: (state, action: PayloadAction<string>) => {
      state.evaluateeName = action.payload;
    },
    setIsLoadingAssignment: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAssignment = action.payload;
    },
    setKpiToSave: (state, action: PayloadAction<KpiToSaveModel>) => {
      state.kpiToSave = action.payload;
    },
  },
});

export const {
  setAssignmentHasError,
  setAssignmentKpis,
  setCompletionPercentage,
  setEvaluateeName,
  setIsLoadingAssignment,
  setKpiToSave,
} = assignmentSlice.actions;
export const assignmentReducer = assignmentSlice.reducer;
