import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { KpiModel } from 'models/kpiModel';
import { KpiToSaveModel } from 'models/kpiToSaveModel';

import {
  generateKpiOptions,
  getButtonLabel,
  KpiPositionValues,
} from 'modules/Evaluations/views/EvaluationFormView/evaluations.utils';

import styles from './KpiCard.module.scss';

interface KpiCardProps {
  kpi: KpiModel;
  position: string;
  onClickBack: () => void;
  onClickNext: (kpiToSaveProps: KpiToSaveModel) => void;
}

const COMMENT_CHAR_LIMIT = 500;

function KpiCard({ kpi, position, onClickBack, onClickNext }: KpiCardProps) {
  const {
    comment,
    departmentKpiId,
    kpiScore,
    kpiName,
    reactives: { name, reactiveId },
    scoreRange,
    selectedKpiId,
  } = kpi;
  const [userComment, setUserComment] = useState<string>(comment);
  const [userScore, setUserScore] = useState<number>(kpiScore);
  const options = generateKpiOptions(scoreRange);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserComment(event.target.value);
  };

  const handleClickOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserScore(+event.target.value);
  };

  const handleClickNextSubmit = () => {
    onClickNext({
      comment: userComment,
      departmentKpiId,
      reactiveId,
      score: userScore,
      selectedKpiId,
    });
  };

  useEffect(() => {
    setUserComment(comment);
    setUserScore(kpiScore);
  }, [selectedKpiId, comment, kpiScore]);

  return (
    <Card className={styles.cardRoot} elevation={0}>
      <Grid columns={10} container justifyContent='center'>
        <Grid item xs={8}>
          <Typography
            className={styles.cardTitle}
            component='p'
            variant='subtitle1'
          >
            {kpiName}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <form>
            <FormControl className={styles.formField}>
              <FormLabel className={styles.description} id='kpiOptions'>
                {name}
              </FormLabel>
              <RadioGroup
                aria-labelledby='kpiOptions'
                className={styles.radioButtonsContainer}
                name='points'
                value={userScore}
                onChange={handleClickOption}
              >
                {options?.map((option) => (
                  <FormControlLabel
                    className={styles.radioButtonLabel}
                    control={
                      <Radio
                        className={styles.radioButton}
                        color='primary'
                        size='medium'
                      />
                    }
                    key={option}
                    label={`${option} pts`}
                    sx={{ margin: '0' }}
                    value={option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl className={styles.formField}>
              <InputLabel htmlFor='kpiComentsArea'>
                Comments (Optional)
              </InputLabel>
              <OutlinedInput
                fullWidth
                id='kpiComentsArea'
                inputProps={{ maxlenght: COMMENT_CHAR_LIMIT }}
                label='Comments (Optional)'
                multiline
                rows={7}
                value={userComment}
                onChange={handleChangeInput}
              />
              <FormHelperText
                className={styles.commentsCharCount}
                component='span'
                id='kpiComentsArea'
              >
                <Typography variant='body2'>
                  {`${userComment.length}/${COMMENT_CHAR_LIMIT}`}
                </Typography>
              </FormHelperText>
            </FormControl>
            <Grid
              columnSpacing={24}
              columns={8}
              container
              justifyContent='flex-end'
            >
              {position !== KpiPositionValues.FIRST && (
                <Grid item xs={2}>
                  <Button variant='outlined' onClick={onClickBack}>
                    Back
                  </Button>
                </Grid>
              )}
              <Grid item xs={2}>
                <Button
                  disabled={!userScore}
                  variant='contained'
                  onClick={handleClickNextSubmit}
                >
                  {getButtonLabel(position)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Card>
  );
}

export default KpiCard;
