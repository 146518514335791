import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { getEvaluations } from 'features/evaluations/evaluation.actions';
import {
  selectCount,
  selectEvaluations,
  selectIsLoadingEvaluations,
  selectOffset,
  selectQueryParams,
} from 'features/evaluations/evaluation.selectors';
import {
  setOffset,
  setQueryParams,
} from 'features/evaluations/evaluation.slice';
import { Toast } from 'features/toast/Toast';
import { setToast } from 'features/toast/toastSlice';
import { EvaluationModel } from 'models/evaluationModel';
import { QueryParamsModel } from 'models/queryParamsModel';

import FiltersPopper from 'components/KFiltersCard/components/Popper/FiltersPopper';
import EvaluationsFilters, {
  EVALUATIONS_FILTERS_SECTIONS,
} from 'components/KFiltersCard/EvaluationsFilters';
import KLayout from 'components/KLayout';
import KSearchBar from 'components/KSearchBar';
import KTable from 'components/KTable';
import AvatarCell from 'components/KTable/components/AvatarCell';
import LinkCell from 'components/KTable/components/LinkCell';
import StatusCell from 'components/KTable/components/StatusCell';
import {
  DEFAULT_ROWS_PER_PAGE,
  rowsPerPageOptions,
} from 'components/KTable/constants';
import KTablePagination from 'components/KTablePagination';
import KTooltip from 'components/KTooltip/KTooltip';
import { formatDate, formatPeriod } from 'utils/date.utils';
import {
  MainTableCell,
  MainTableRow,
  SortingOptions,
  Status,
} from 'utils/table.utils';

import { mainTableHeaderColumns } from './evaluations.utils';

import styles from './Evaluations.module.scss';

function EvaluationsView(): ReactElement {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const history = useHistory();
  const { state } = useLocation<{ evaluatee: string }>();
  const count = useSelector(selectCount);
  const evaluations = useSelector(selectEvaluations);
  const loading = useSelector(selectIsLoadingEvaluations);
  const offset = useSelector(selectOffset);
  const queryParams = useSelector(selectQueryParams);

  const [filtersAnchor, setFiltersAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [searchValue, setSearchValue] = useState('');
  const [sortingOptions, setSortingOptions] = useState<SortingOptions>([]);

  useEffect(() => {
    if (state?.evaluatee) {
      dispatch(
        setToast({
          message: `${state.evaluatee}'s evaluation was successfully submitted!`,
          isActive: true,
          type: 'info',
        }),
      );
      history.replace({ state: {} });
    }
    dispatch(
      getEvaluations({ limit: rowsPerPage, offset, where: queryParams }),
    );
  }, [dispatch, history, rowsPerPage, state, offset, queryParams]);

  const getRows = useMemo(
    () =>
      (evaluations: EvaluationModel[]): MainTableRow[] =>
        evaluations.map((evaluation: EvaluationModel) => {
          const {
            id,
            avatar,
            dueDate,
            period,
            project,
            status,
            toBeEvaluated,
          } = evaluation;
          const formattedDueDate = formatDate(dueDate);
          const quarter = formatPeriod(period);
          const [, year] = period.split('-');

          const toBeEvaluatedCell: MainTableCell = {
            value: <AvatarCell avatarURL={avatar} text={toBeEvaluated} />,
          };
          const statusCell: MainTableCell = {
            value: <StatusCell status={status} />,
          };
          const projectCell = { value: project };
          const periodCell = { value: quarter };
          const yearCell = { value: year };
          const dueDateCell = { value: formattedDueDate };
          const linkCell: MainTableCell = {
            value:
              status === Status.New || status === Status.InProgress ? (
                <KTooltip position={[-80, 0]} title='Go to evaluation'>
                  <LinkCell to={`${url}/${id}`} />
                </KTooltip>
              ) : (
                <Fragment />
              ),
          };

          const innerCells: MainTableCell[] = [
            toBeEvaluatedCell,
            statusCell,
            projectCell,
            periodCell,
            yearCell,
            dueDateCell,
            linkCell,
          ];

          return {
            id,
            innerCells,
          };
        }),
    [url],
  );

  const handleClickSorting = (sortingValues: SortingOptions) => {
    setSortingOptions(sortingValues);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (newPage > page) {
      dispatch(setOffset(offset + (newPage - page) * rowsPerPage));
    } else {
      dispatch(setOffset(offset - (page - newPage) * rowsPerPage));
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(setOffset(0));
    setPage(0);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClickApplyFilters = (queryParams: QueryParamsModel) => {
    dispatch(setQueryParams(queryParams));
    setAreFiltersOpen(!areFiltersOpen);
  };

  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchor(event.currentTarget);
    setAreFiltersOpen(!areFiltersOpen);
  };

  return (
    <KLayout>
      <FiltersPopper anchorEl={filtersAnchor} open={areFiltersOpen}>
        <EvaluationsFilters
          sections={EVALUATIONS_FILTERS_SECTIONS}
          onClickApplyFilters={handleClickApplyFilters}
        />
      </FiltersPopper>
      <Box className={styles.rootBox}>
        <Box>
          <Toast />
          <Typography component='h1' variant='h5'>
            Evaluations
          </Typography>
        </Box>
        <Card className={styles.rootCard}>
          <Grid
            className={styles.gridRoot}
            columnSpacing='24px'
            columns={12}
            container
          >
            <Grid className={styles.itemSearch} item sm={10} xs={12}>
              <KSearchBar
                placeholder='Enter a name'
                value={searchValue}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid className={styles.itemFilters} item sm={2} xs={12}>
              <Button variant='outlined' onClick={handleClickFilters}>
                Filters
              </Button>
            </Grid>
            <Grid className={styles.itemTable} item xs={12}>
              <KTable
                columns={mainTableHeaderColumns}
                isLoading={loading}
                noRowsMessage='Evaluations not found'
                rows={getRows(evaluations)}
                sortingOptions={sortingOptions}
                onClickSort={handleClickSorting}
              />
            </Grid>
          </Grid>
        </Card>
        <Box>
          <KTablePagination
            count={count}
            itemType='evaluation(s)'
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </KLayout>
  );
}

export default EvaluationsView;
