import React from 'react';
import Pagination from '@mui/material/Pagination';

interface KTablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
}

function KTablePaginationActions(props: KTablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    onPageChange(null, page - 1);
  };

  const pageCount = Math.ceil(count / rowsPerPage);

  return (
    <Pagination
      count={pageCount}
      page={page + 1}
      showFirstButton
      showLastButton
      onChange={handleChangePage}
    />
  );
}

export default KTablePaginationActions;
