import React from 'react';
import { Box, Grid, Paper } from '@mui/material';

import { ResultCardData } from 'components/KResumeKPI/resultCardData.types';

import KResumeCard from './components/KResultCard/index';

import styles from './KResumeKPI.module.scss';

interface KResumeKPIProps {
  results: ResultCardData[];
}

function KResumeKPI({ results }: KResumeKPIProps) {
  return (
    <Paper
      className={styles.resumeContainer}
      elevation={0}
      sx={{ width: '100%' }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {results.map(({ id, text, value }: ResultCardData, index) => (
            <KResumeCard
              isLastItem={index + 1 === results.length}
              key={id}
              text={text}
              value={value}
            />
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

export default KResumeKPI;
