import React, { ReactElement } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { KpiModel } from 'models/summaryModel';

import KChip from 'components/KChip';
import KpiRanking from 'components/KpiRanking';

import styles from './KSummaryKpis.module.scss';

interface KSummaryKpisProps {
  isRankingDepartments?: boolean;
  isTopTwo?: boolean;
  kpis: KpiModel[];
}

const KSummaryKpis = ({
  isRankingDepartments = false,
  isTopTwo = false,
  kpis,
}: KSummaryKpisProps): ReactElement => (
  <Box className={styles.summaryBox}>
    {kpis.map(({ maxScore, myScore, kpiId, kpiName, group }) => (
      <Card className={styles.summaryCard} key={kpiId}>
        <KpiRanking maxScore={maxScore} position={group} score={myScore} />
        <Typography component='p' flexGrow={2} variant='subtitle2'>
          {kpiName}
        </Typography>
        {isTopTwo && (
          <KChip isRankingDepartments={isRankingDepartments} position={group} />
        )}
      </Card>
    ))}
  </Box>
);

export default KSummaryKpis;
