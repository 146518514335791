import React, { ReactElement, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';

import { ReactComponent as EmptyOverview } from 'assets/images/evaluationsOverviewEmptyState.svg';
import KEvaluationOverview from 'components/KEvaluationOverview';
import FiltersPopper from 'components/KFiltersCard/components/Popper';
import EvaluationsCompanyFilters, {
  COMPANY_FILTERS_SECTIONS,
} from 'components/KFiltersCard/EvaluationsCompanyFilters';
import KLayout from 'components/KLayout';

import { evaluationsCompanyTabs } from './evaluationsCompany.utils';
import { evaluations } from './mockData';

import styles from './EvaluationsCompany.module.scss';

function EvaluationsCompanyView(): ReactElement {
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const [filtersAnchor, setFiltersAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [tabValue, setTabValue] = useState('overview');

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabValue: string,
  ) => {
    setTabValue(newTabValue);
  };

  const handleClickApplyFilters = (queryParams: {
    department: number;
    status: string[];
  }) => {
    // TODO: Create company evaluations Redux files (slice, actions,selectors) and Dispatch queryParams to filter data
    // eslint-disable-next-line no-console
    console.log(queryParams);
    setAreFiltersOpen(!areFiltersOpen);
  };

  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchor(event.currentTarget);
    setAreFiltersOpen(!areFiltersOpen);
  };

  return (
    <KLayout>
      <FiltersPopper anchorEl={filtersAnchor} open={areFiltersOpen}>
        <EvaluationsCompanyFilters
          sections={COMPANY_FILTERS_SECTIONS}
          onClickApplyFilters={handleClickApplyFilters}
        />
      </FiltersPopper>
      <Typography component='h1' variant='h5'>
        Company Evaluations
      </Typography>
      <Grid columnSpacing={24} container marginBottom={40} rowSpacing={22}>
        <Grid item xs={8}>
          <Box marginTop={20}>
            <Tabs
              classes={{ flexContainer: styles.tabsContainer }}
              value={tabValue}
              onChange={handleChangeTab}
            >
              {evaluationsCompanyTabs.map(({ label, value }) => (
                <Tab
                  key={value}
                  label={
                    <Typography component='h2' variant='subtitle2'>
                      {label}
                    </Typography>
                  }
                  value={value}
                />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box columnGap='24px' display='flex'>
            <Button
              disabled={!evaluations.length}
              variant='outlined'
              onClick={handleClickFilters}
            >
              <Typography component='p' variant='body1'>
                Filters
              </Typography>
            </Button>
            <Button variant='contained'>
              <Typography component='p' variant='body1'>
                New Evaluation
              </Typography>
            </Button>
          </Box>
        </Grid>
        {!evaluations.length ? (
          <Grid
            alignItems='center'
            display='flex'
            flexDirection='column'
            item
            marginTop={24}
            rowGap={12}
            xs={12}
          >
            <EmptyOverview />
            <Typography color='grey.600' component='p' variant='h4'>
              There are not current evaluations
            </Typography>
          </Grid>
        ) : (
          evaluations.map(
            ({
              department,
              completedCount,
              evaluationsCount,
              averageTotal,
              maxTotal,
              author,
              quarter,
              year,
              startDate,
              endDate,
              assignmentsMissing,
              status,
              id,
            }) => (
              <Grid item key={id} xs={4}>
                <KEvaluationOverview
                  assignmentsMissing={assignmentsMissing}
                  author={author}
                  averageTotal={averageTotal}
                  completedCount={completedCount}
                  department={department}
                  endDate={endDate}
                  evaluationsCount={evaluationsCount}
                  maxTotal={maxTotal}
                  quarter={quarter}
                  startDate={startDate}
                  status={status}
                  year={year}
                />
              </Grid>
            ),
          )
        )}
      </Grid>
    </KLayout>
  );
}

export default EvaluationsCompanyView;
