import React, { useState } from 'react';
import { Divider, SelectChangeEvent } from '@mui/material';
import { FilterSectionsModel } from 'models/filtersSectionsModel';

import FiltersCard from '../components/Card';
import CheckboxFilters from '../components/Sections/CheckboxFilters';
import DropdownFilters from '../components/Sections/DropdownFilters';

interface EvaluationsCompanyFiltersProps {
  sections: Record<string, FilterSectionsModel>;
  onClickApplyFilters: (queryParams: {
    department: number;
    status: string[];
  }) => void;
}

function EvaluationsCompanyFilters({
  sections,
  onClickApplyFilters,
}: EvaluationsCompanyFiltersProps) {
  const { status, department } = sections;
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<string>('');

  const handleChangeStatusFilters = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (statusFilters.includes(event.target.name)) {
      const newFiltersValue = statusFilters.filter(
        (status) => status !== event.target.name,
      );
      return setStatusFilters(newFiltersValue);
    }

    return setStatusFilters([...statusFilters, event.target.name]);
  };

  const handleSelectDepartmentFilter = (event: SelectChangeEvent) => {
    setDepartmentFilter(event.target.value);
  };

  const handleClearFilters = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setStatusFilters([]);
    setDepartmentFilter('');
  };

  const handleClickApply = (_event: React.MouseEvent<HTMLButtonElement>) => {
    const queryParams = {
      department: +departmentFilter,
      status: [...statusFilters],
    };
    onClickApplyFilters(queryParams);
  };

  return (
    <FiltersCard
      onClickApply={handleClickApply}
      onClickClear={handleClearFilters}
    >
      <CheckboxFilters
        filters={statusFilters}
        section={status}
        onChange={handleChangeStatusFilters}
      />
      <Divider sx={{ marginBottom: '24px' }} />
      <DropdownFilters
        section={department}
        value={departmentFilter}
        onSelect={handleSelectDepartmentFilter}
      />
    </FiltersCard>
  );
}

export default EvaluationsCompanyFilters;
