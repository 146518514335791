import { getQuarter, getYear, parse, parseISO } from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';

function formatDate(date: string) {
  return formatInTimeZone(parseISO(date), 'MM/dd/yyyy', 'UTC');
}

function formatInTimeZone(date: Date, format: string, timeZone: string) {
  return formatTZ(utcToZonedTime(date, timeZone), format, { timeZone });
}

function formatPeriod(period: string) {
  const [, , end] = period.split('-');
  const quarter = getQuarter(parse(end, 'MMM', new Date(0, 0, 1)));
  return `Q${quarter}`;
}

function getCurrentQuarter() {
  return getQuarter(new Date());
}

function getCurrentYear() {
  return getYear(new Date());
}

export {
  formatDate,
  formatInTimeZone,
  formatPeriod,
  getCurrentQuarter,
  getCurrentYear,
};
