import React, { Fragment, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import KBackBar from 'components/KBackBar';
import FiltersPopper from 'components/KFiltersCard/components/Popper/FiltersPopper';
import EvaluatorsAssignationFilters, {
  EVALUATORS_ASSIGNATION_FILTERS_SECTIONS,
} from 'components/KFiltersCard/EvaluatorsAssignationFilters';
import KLayout from 'components/KLayout';
import KSearchBar from 'components/KSearchBar';
import KTable from 'components/KTable';
import ListCell from 'components/KTable/components/ListCell';
import {
  DEFAULT_ROWS_PER_PAGE,
  rowsPerPageOptions,
} from 'components/KTable/constants';
import KTablePagination from 'components/KTablePagination';
import { MainTableCell, MainTableRow, SortingOptions } from 'utils/table.utils';

import {
  EvaluatorData,
  mainTableHeaderColumns,
} from './evaluatorsAssignation.utils';
import { evaluators } from './mockData';

// TODO: add redux implementation to replace mock data
import styles from './EvaluatorsAssignation.module.scss';

function EvaluatorsAssignation(): ReactElement {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [tableData] = useState(evaluators);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortingOptions, setSortingOptions] = useState<SortingOptions>([]);
  const [offset, setOffset] = useState(0);
  const [filtersAnchor, setFiltersAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const evaluateeName = 'Daniel Alcudia';

  const handleClickFiltersButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setFiltersAnchor(event.currentTarget);
    setAreFiltersOpen(!areFiltersOpen);
  };

  const handleClickApplyFilters = (queryParams: {
    evaluatorsAssignation: string[];
    evaluatorsPosition: string;
  }) => {
    // TODO: add logic to handle queryParams when filtering
    //  dispatch(setQueryParams(queryParams));
    setAreFiltersOpen(!areFiltersOpen);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (newPage > page) {
      setOffset(offset + (newPage - page) * rowsPerPage);
    } else {
      setOffset(offset - (page - newPage) * rowsPerPage);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleClickAssign = () => {
    // TODO: add logic to assign a new evaluator when clicking "Assign"
  };

  const handleChangeCheckbox = (
    evaluatorId: number | string,
    checked: boolean,
  ) => {
    // TODO: add logic to handle clicking an evaluator to assing
  };

  const handleClickSorting = (sortingValues: SortingOptions) => {
    setSortingOptions(sortingValues);
  };

  const getRows = (tableData: EvaluatorData[]): MainTableRow[] =>
    tableData.map((evaluator: EvaluatorData) => {
      const { name, position, id, commonProject, assigned } = evaluator;
      const nameCell: MainTableCell = {
        value: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              color='primary'
              sx={{ paddingLeft: 0 }}
              onChange={(event) =>
                handleChangeCheckbox(id, event.target.checked)
              }
            />
            <Typography className={styles.tableCell}>{name}</Typography>
          </Box>
        ),
      };
      const positionCell: MainTableCell = {
        value: position,
      };
      const commonProjectCell: MainTableCell = {
        value: <ListCell list={commonProject} size='300' />,
      };
      const assignedCell: MainTableCell = {
        value: (
          <Box display='flex'>
            <Typography className={styles.assigned} sx={{ marginRight: '4px' }}>
              {assigned}
            </Typography>
            <Typography className={styles.tableCell}>
              {assigned > 1 ? 'evaluations' : 'evaluation'}
            </Typography>
          </Box>
        ),
      };

      const innerCells: MainTableCell[] = [
        nameCell,
        positionCell,
        commonProjectCell,
        assignedCell,
      ];

      return {
        id,
        innerCells,
      };
    });

  return (
    <Fragment>
      <KBackBar label='Back' onClickBack={handleClickBack} />
      <KLayout>
        <FiltersPopper anchorEl={filtersAnchor} open={areFiltersOpen}>
          <EvaluatorsAssignationFilters
            sections={EVALUATORS_ASSIGNATION_FILTERS_SECTIONS}
            onClickApplyFilters={handleClickApplyFilters}
          />
        </FiltersPopper>
        <Fragment>
          <Box display='flex'>
            <Typography
              className={styles.orginalPath}
              component='p'
              marginBottom='16px'
              variant='body2'
            >
              Company Evaluations&gt; New Evaluation&gt;
            </Typography>
            <Typography component='p' marginLeft='4px' variant='body2'>
              {evaluateeName}
            </Typography>
          </Box>
          <Typography component='h1' variant='h5'>
            Evaluation of {evaluateeName}
          </Typography>
        </Fragment>
        <Card className={styles.rootCard} elevation={0}>
          <Grid columnSpacing='10px' columns={12} container rowSpacing='32px'>
            <Grid item xs={12}>
              <Typography component='h2' variant='subtitle1'>
                Selected evaluators
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '72px',
                  marginBottom: '72px',
                }}
              >
                <Typography
                  className={styles.emptySubtitle}
                  component='h2'
                  variant='h4'
                >
                  No evaluators selected yet.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ marginBottom: '32px' }} />
              <Typography
                className={styles.instruction}
                component='h2'
                variant='subtitle1'
              >
                People who can evaluate {evaluateeName}
              </Typography>
              <Grid columnSpacing='20px' columns={12} container>
                <Grid className={styles.itemSearch} item sm={10} xs={12}>
                  <KSearchBar
                    placeholder='Search by name'
                    value={searchValue}
                    onChange={handleChangeSearch}
                  />
                </Grid>
                <Grid className={styles.itemFilters} item sm={2} xs={12}>
                  <Button variant='outlined' onClick={handleClickFiltersButton}>
                    Filters
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Fragment>
                <KTable
                  columns={mainTableHeaderColumns}
                  isLoading={false}
                  noRowsMessage='Teammates not found'
                  rows={getRows(tableData)}
                  sortingOptions={sortingOptions}
                  onClickSort={handleClickSorting}
                />
                <Box sx={{ marginTop: '32px' }}>
                  <KTablePagination
                    count={tableData.length}
                    itemType='evaluation(s)'
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Fragment>
            </Grid>
            <Grid
              item
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              xs={12}
            >
              <Grid item sm={2} xs={12}>
                <Button
                  disabled
                  variant='contained'
                  onClick={handleClickAssign}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </KLayout>
    </Fragment>
  );
}

export default EvaluatorsAssignation;
