import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import KNavBar from 'components/KNavBar';

import ResultsView from './views/ResultsView';

const ResultsRouter = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path}>
        <KNavBar path={path} />
        <Route component={ResultsView} exact path={path} />
      </Route>
    </Switch>
  );
};

export default ResultsRouter;
