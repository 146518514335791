import { fetchUpcomingEvaluation } from 'services/summaryService';

import { AppThunk } from '../../app/store';
import { describeApiError } from '../../utils/errorUtils';
import { setToast } from '../toast/toastSlice';

import {
  setIsLoadingUpcomingEvaluation,
  setUpcomingEvaluationDate,
} from './upcomingEvaluation.slice';

export const getUpcomingEvaluation = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoadingUpcomingEvaluation(true));
  try {
    const { data } = await fetchUpcomingEvaluation();
    dispatch(setUpcomingEvaluationDate(data));
  } catch (error) {
    dispatch(
      setToast({
        message: describeApiError(error),
        isActive: true,
        type: 'error',
      }),
    );
  } finally {
    dispatch(setIsLoadingUpcomingEvaluation(false));
  }
};
