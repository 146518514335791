import React from 'react';
import {
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FilterSectionsModel } from 'models/filtersSectionsModel';

interface DropdownFilterProps {
  section: FilterSectionsModel;
  value: string;
  onSelect: (event: SelectChangeEvent<string>) => void;
}

const FilterSection = ({ section, value, onSelect }: DropdownFilterProps) => {
  const { label, options } = section;

  return (
    <Grid
      columns={8}
      container
      justifyContent='center'
      sx={{ marginBottom: '24px' }}
    >
      <Grid item md={2} xs={8}>
        <FormLabel>
          <Typography
            component='legend'
            marginBottom='12px'
            variant='subtitle1'
          >
            {label}
          </Typography>
        </FormLabel>
      </Grid>
      <Grid item md={6} xs={8}>
        <FormControl fullWidth>
          <InputLabel id={`select-${label}-label`}>{label}</InputLabel>
          <Select
            id={`select-${label}`}
            label={label}
            labelId={`select-${label}-label`}
            value={value}
            onChange={onSelect}
          >
            {options.map((option) => {
              const { label, param } = option;
              return (
                <MenuItem key={label} value={param}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FilterSection;
