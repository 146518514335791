import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { getKpiDetails } from 'features/kpiDetails/kpiDetails.actions';
import { getResume } from 'features/resume/resume.actions';

import KDropdown from '../KDropdown/KDropdown';

import { KQuarterTabsProps } from './quarters.types';

import style from './KQuarterTabs.module.scss';

export default function KQuarterTabs({ period }: KQuarterTabsProps) {
  const dispatch = useDispatch();
  const [selectedQuarter, setSelectedQuarter] = useState(0);
  const [selectedYear, setSelectedYear] = useState('');
  const dropdownOptions = [{ label: '2022', value: 2022 }];

  const onChangeQuarter = (event: React.SyntheticEvent, newQuarter: number) => {
    setSelectedQuarter(newQuarter);
  };

  const onSelectYear = (year: string) => {
    setSelectedYear(year);
  };

  useEffect(() => {
    dispatch(getResume(`${selectedQuarter + 1}`, Number(selectedYear)));
    dispatch(getKpiDetails(`${selectedQuarter + 1}`, Number(selectedYear)));
  }, [dispatch, selectedQuarter, selectedYear]);

  return (
    <Box className={style.quarterTabsContainer}>
      <Tabs
        className={style.tabsContainer}
        value={selectedQuarter}
        onChange={onChangeQuarter}
      >
        {period.map(({ id, isActive, periodName }) => (
          <Tab
            className={style.tab}
            disabled={!isActive}
            key={id}
            label={
              <Typography component='h2' variant='subtitle2'>
                {periodName}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Box className={style.dropdownContainer}>
        <KDropdown
          initialValue={dropdownOptions[0].label}
          options={dropdownOptions}
          onSelect={onSelectYear}
        />
      </Box>
    </Box>
  );
}
