import React, { ReactElement } from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';

import styles from './KPendingReminder.module.scss';

interface KPendingReminderProps {
  personalCount: number;
  teammateCount: number;
}

const KPendingReminder = ({
  personalCount,
  teammateCount,
}: KPendingReminderProps): ReactElement => {
  const pluralizeLabel = (count: number) => (count !== 1 ? 's' : '');

  return (
    <Card className={styles.pendingCard}>
      <Box className={styles.pendingBox}>
        <Typography component='p' variant='h4'>
          {teammateCount}
        </Typography>
        <Typography component='p' variant='body1'>
          Pending teammate{pluralizeLabel(teammateCount)} evaluation
          {pluralizeLabel(teammateCount)}
        </Typography>
      </Box>
      <Divider flexItem orientation='vertical' variant='middle' />
      <Box className={styles.pendingBox}>
        <Typography component='p' variant='h4'>
          {personalCount}
        </Typography>
        <Typography component='p' variant='body1'>
          Pending personal evaluation{pluralizeLabel(personalCount)}
        </Typography>
      </Box>
    </Card>
  );
};

export default KPendingReminder;
