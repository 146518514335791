import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResumeModel } from 'models/resumeModel';

interface ResumeState {
  isLoadingResume: boolean;
  resume: ResumeModel;
}

const initialState: ResumeState = {
  isLoadingResume: false,
  resume: {
    roundedAveragePercentage: 0,
    roundedCompanyAveragePercentage: 0,
    roundedPeersAveragePercentage: 0,
  },
};

export const resumeSlice = createSlice({
  name: 'resume',
  initialState,
  reducers: {
    setIsLoadingResume: (state, action: PayloadAction<boolean>) => {
      state.isLoadingResume = action.payload;
    },
    setResume: (state, action: PayloadAction<ResumeModel>) => {
      state.resume = action.payload;
    },
  },
});

export const { setIsLoadingResume, setResume } = resumeSlice.actions;

export const resumeReducer = resumeSlice.reducer;
