import React, { Fragment } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';

import styles from './KpiToBeEvaluated.module.scss';

interface KpiPoints {
  points: number;
  title: string;
}

interface KpiToBeEvaluatedProps {
  kpis: KpiPoints[];
  totalPoints: KpiPoints[];
  onClickCheckbox: (kpi: KpiPoints, checked: boolean) => void;
}

function KpiToBeEvaluated({
  kpis,
  totalPoints,
  onClickCheckbox,
}: KpiToBeEvaluatedProps) {
  const handleClickCheckbox =
    (points: number, title: string) =>
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      onClickCheckbox({ points, title }, checked);

  return (
    <Fragment>
      <Box sx={{ display: 'flex', margin: '24px 0' }}>
        <Typography
          className={styles.pointsText}
          component='span'
          variant='body1'
        >
          Total points for this evaluation:
        </Typography>
        <Typography
          className={styles.totalPointsText}
          component='span'
          sx={{ marginLeft: '4px' }}
          variant='body1'
        >
          {totalPoints.reduce((sum, { points }) => sum + points, 0)}
        </Typography>
      </Box>
      {kpis.map(({ points, title }) => (
        <Box
          key={`${title}-${uuid()}`}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            checked={totalPoints.some((kpi) => kpi.title === title)}
            color='primary'
            sx={{ paddingLeft: 0 }}
            onChange={handleClickCheckbox(points, title)}
          />
          <Typography
            className={styles.pointsText}
            component='span'
            sx={{ marginLeft: '4px' }}
            variant='body1'
          >
            {points} points - {title}
          </Typography>
        </Box>
      ))}
    </Fragment>
  );
}

export default KpiToBeEvaluated;
