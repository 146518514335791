import { paginationClasses } from '@mui/material/Pagination';
import { createTheme } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import {
  getLetterSpacing,
  getLineHeight,
  pixelToRem,
} from 'styles/theme.utils';

import variables from 'styles/partials/_variables.module.scss';

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      dark: variables.primary900,
      light: variables.primary100,
      main: variables.primary700,
    },
    secondary: {
      dark: variables.secondary1_900,
      light: variables.secondary1_100,
      main: variables.secondary1_600,
    },
    background: {
      default: variables.background,
    },
    divider: '#E3E3E3',
    grey: {
      100: variables.grey100,
      200: variables.grey200,
      300: variables.grey300,
      400: variables.grey400,
      500: variables.grey500,
      600: variables.grey600,
      700: variables.grey700,
      800: variables.grey800,
    },
    error: {
      main: variables.errorMain,
    },
    success: {
      main: variables.successMain,
    },
    warning: {
      main: variables.warningMain,
    },
  },
  typography: {
    fontFamily: variables.fontNunito,
  },
  spacing: 1,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: variables.space_xs,
          textTransform: 'none',
          padding: `${variables.space_sm} ${variables.space_md}`,
          height: '48px',
          fontSize: pixelToRem(variables.fontSize_lg),
          fontWeight: variables.fontWeightBold,
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_lg,
            lineHeightPx: variables.lineHeight_lg,
          }),
          letterSpacing: getLetterSpacing(1),
          textAlign: 'center',
          fontFamily: variables.fontNunito,
          cursor: 'pointer',
          width: '100%',
        },
        contained: {
          backgroundColor: variables.primary700,
          color: variables.white,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: variables.primary900,
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: variables.grey200,
            color: variables.grey500,
          },
        },

        outlined: {
          backgroundColor: variables.white,
          border: `solid 1px ${variables.primary700}`,
          color: variables.primary700,
          '&:hover': {
            backgroundColor: variables.primary700,
            color: variables.white,
          },
          '&:disabled': {
            backgroundColor: variables.white,
            border: `solid 1px ${variables.grey500}`,
            color: variables.grey500,
          },
        },

        text: {
          color: variables.primary700,
          '&:hover': {
            backgroundColor: variables.secondary2_300,
          },
          '&:disabled': {
            color: variables.grey500,
          },
        },

        textSecondary: {
          color: variables.secondary1_600,
          fontSize: pixelToRem(variables.fontSize_md),
          '&:hover': {
            color: variables.secondary1_900,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: variables.grey500,
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0',
          width: '100%',
        },
        marginNormal: {
          marginBottom: '0',
          marginTop: '0',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: `${variables.grey700} !important`,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: variables.grey700,
          left: '2px',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
          fontSize: pixelToRem(variables.fontSize_md),
          fontFamily: variables.fontNunito,
          position: 'absolute',
          top: '-4px',
        },

        shrink: {
          color: `${variables.grey700} !important`,
          height: 'unset',
          left: '0',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_xs,
            lineHeightPx: variables.lineHeight_md,
          }),
          padding: '4px',
          top: '0',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: variables.background,
          borderRadius: '8px',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
          padding: '0',
          width: '100%',
        },
        input: {
          color: variables.grey700,
          fontSize: pixelToRem(variables.fontSize_md),
          height: 'unset',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
          padding: '12px 16px',
        },
        notchedOutline: {
          border: `1px ${variables.grey700} solid`,
          padding: '0 12px',
          borderColor: `${variables.grey700} !important`,
          '& > legend:first-of-type > span:first-of-type': {
            paddingRight: '2px',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          color: variables.grey700,
          fontSize: pixelToRem(variables.fontSize_md),
          padding: '12px 16px',
        },
        icon: {
          right: '16px',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: pixelToRem(variables.fontSize_md),
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'none',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          color: 'inherit',
          fontSize: pixelToRem(variables.fontSize_md),
          minHeight: variables.space_2xl,
          minWidth: 'fit-content',
          padding: '0',
          textTransform: 'capitalize',

          '&:hover': {
            color: variables.primary700,
          },

          [`&.${tabClasses.selected} > p`]: {
            fontWeight: variables.fontWeightBold,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: variables.space_2xl,
        },
        flexContainer: {
          gap: variables.space_3xl,
        },
        indicator: {
          backgroundColor: variables.primary700,
          height: '2px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          userSelect: 'none',
        },
        h1: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_4xl),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: '0',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_4xl,
            lineHeightPx: variables.lineHeight_3xl,
          }),
        },
        h2: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_3xl),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(0.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_3xl,
            lineHeightPx: variables.lineHeight_2xl,
          }),
        },
        h3: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_2xl),
          fontWeight: variables.fontWeightBold,
          letterSpacing: getLetterSpacing(2),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_2xl,
            lineHeightPx: variables.lineHeight_xl,
          }),
        },
        h4: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_xl),
          fontWeight: variables.fontWeightBold,
          letterSpacing: getLetterSpacing(1.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_xl,
            lineHeightPx: variables.lineHeight_xl,
          }),
        },
        h5: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_xl),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(1),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_xl,
            lineHeightPx: variables.lineHeight_xl,
          }),
        },
        subtitle1: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_lg),
          fontWeight: variables.fontWeightMedium,
          letterSpacing: getLetterSpacing(1),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_lg,
            lineHeightPx: variables.lineHeight_lg,
          }),
        },
        subtitle2: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_lg),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(1),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_lg,
            lineHeightPx: variables.lineHeight_lg,
          }),
        },
        body1: {
          fontFamily: variables.fontNunito,
          fontSize: pixelToRem(variables.fontSize_md),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(0.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
        },
        body2: {
          fontFamily: variables.fontNunito,
          fontSize: pixelToRem(variables.fontSize_sm),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(1.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_sm,
            lineHeightPx: variables.lineHeight_lg,
          }),
        },
        button: {
          fontFamily: variables.fontRoboto,
          fontSize: pixelToRem(variables.fontSize_xl),
          fontWeight: variables.fontWeightBold,
          letterSpacing: getLetterSpacing(1.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_xl,
            lineHeightPx: variables.lineHeight_xl,
          }),
          textTransform: 'none',
        },
        caption: {
          fontFamily: variables.fontNunito,
          fontSize: pixelToRem(variables.fontSize_xs),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(0.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_xs,
            lineHeightPx: variables.lineHeight_md,
          }),
        },
        overline: {
          fontFamily: variables.fontNunito,
          fontSize: pixelToRem(variables.fontSize_xs),
          fontWeight: variables.fontWeightRegular,
          letterSpacing: getLetterSpacing(1.5),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_xs,
            lineHeightPx: variables.lineHeight_md,
          }),
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: variables.secondary1_600,
          fontFamily: variables.fontNunito,
          fontSize: pixelToRem(variables.fontSize_md),
          fontWeight: variables.fontWeightBold,
          letterSpacing: getLetterSpacing(1),
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
          textDecoration: 'none',
          '&:disabled': {
            color: variables.grey500,
          },
          '&:hover': {
            textDecoration: 'underline',
            color: variables.secondary1_900,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: pixelToRem(variables.fontSize_md),
          borderBottom: `0.5px solid ${variables.grey300}`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
          marginLeft: '0',
          width: '100%',
          padding: '0',
        },
        input: {
          color: variables.black,
          fontFamily: variables.fontNunito,
          fontSize: pixelToRem(variables.fontSize_md),
          height: 'unset',
          letterSpacing: '0',
          lineHeight: getLineHeight({
            fontSizePx: variables.fontSize_md,
            lineHeightPx: variables.lineHeight_lg,
          }),
          padding: '12px 16px',
          width: '100%',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
          [`& .${tablePaginationClasses.toolbar}`]: {
            display: 'grid',
            gridColumnGap: '24px',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridTemplateRows: 'fit-content',
            height: 'auto',
            padding: '0',
            width: '100%',
            [`& .${paginationClasses.root}`]: {
              flexShrink: '0',
              gridArea: '1/4/2/10',
              justifySelf: 'center',
              width: '100%',
              [`& .${paginationClasses.ul}`]: {
                justifyContent: 'center',
              },
            },
          },
        },
        displayedRows: {
          color: variables.grey700,
          gridArea: '1/1/2/4',
          justifySelf: 'start',
          width: '100%',
        },
        selectLabel: {
          color: variables.grey700,
          gridArea: '1/11/2/13',
          justifySelf: 'end',
          marginRight: '62px',
          position: 'relative',
          textAlign: 'right',
          width: '100%',
        },
        selectRoot: {
          gridArea: '1/12/2/13',
          justifySelf: 'end',
          marginLeft: '0',
          marginRight: '0',
          [`& .${tablePaginationClasses.selectIcon}`]: {
            right: '0',
          },
        },
        spacer: {
          display: 'none',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          display: 'flex',
          gap: '2px',
          '& li:nth-last-of-type(2)': {
            marginLeft: '2px',
            marginRight: '-4px',
          },
          '& li:nth-of-type(2)': {
            marginLeft: '-4px',
            marginRight: '2px',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          color: variables.grey700,
          fontSize: pixelToRem(variables.fontSize_sm),
          margin: 0,
          '&[aria-current = true]': {
            backgroundColor: 'inherit',
            border: `solid 1px ${variables.primary700}`,
          },
          '&[disabled]': {
            color: variables.grey500,
            opacity: 1,
          },
        },
        icon: {
          height: '24px',
          width: '24px',
        },
        firstLast: {
          color: variables.secondary2_700,
        },
        previousNext: {
          color: variables.secondary2_700,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: variables.grey300,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0 2px 15px 0 rgba(32, 100, 123, 0.1)',
          '&:before': {
            opacity: 0,
          },
          '&:first-of-type': {
            borderRadius: '8px',
          },
          '&:last-of-type': {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: variables.white,
          borderRadius: '8px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          alignItems: 'center',
          backgroundColor: variables.grey800,
          borderRadius: 8,
          color: variables.white,
          display: 'flex',
          fontFamily: `${variables.fontNunito}`,
          fontSize: pixelToRem(variables.fontSize_xs),
          justifyContent: 'center',
          maxHeight: '80px',
          padding: '8px 16px',
          textAlign: 'center',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: variables.primary700,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
  },
});
export default customTheme;
