import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

import textStyles from '../KTableCell/KTableCell.module.scss';

interface AvatarCellProps {
  avatarURL: string;
  text: string;
}

function AvatarCell({ avatarURL, text }: AvatarCellProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: 20 }}>
        <Avatar alt={text} src={avatarURL} />
      </Box>
      <Typography
        className={textStyles.tableCellText}
        component='p'
        variant='body1'
      >
        {text}
      </Typography>
    </Box>
  );
}

export default AvatarCell;
