import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PendingEvaluationsModel } from 'models/pendingEvaluationsModel';

interface PendingEvaluationsState {
  isLoadingPendingEvaluations: boolean;
  pendingEvaluationsCount: PendingEvaluationsModel;
}

const initialState: PendingEvaluationsState = {
  isLoadingPendingEvaluations: true,
  pendingEvaluationsCount: {
    personalCount: 0,
    teammateCount: 0,
  },
};

export const pendingEvaluationsSlice = createSlice({
  name: 'pendingEvaluations',
  initialState,
  reducers: {
    setPendingEvaluationsCount: (
      state,
      action: PayloadAction<PendingEvaluationsModel>,
    ) => {
      state.pendingEvaluationsCount = action.payload;
    },
    setIsLoadingPendingEvaluations: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPendingEvaluations = action.payload;
    },
  },
});

export const { setPendingEvaluationsCount, setIsLoadingPendingEvaluations } =
  pendingEvaluationsSlice.actions;

export const pendingEvaluationsReducer = pendingEvaluationsSlice.reducer;
