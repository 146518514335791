import { RootState } from 'app/store';

export const selectCount = (state: RootState) => state.evaluations.count;
export const selectEvaluations = (state: RootState) =>
  state.evaluations.evaluations;
export const selectOffset = (state: RootState) => state.evaluations.offset;
export const selectIsLoadingEvaluations = (state: RootState) =>
  state.evaluations.isLoadingEvaluations;
export const selectQueryParams = (state: RootState) =>
  state.evaluations.queryParams;
