import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import KNavBar from 'components/KNavBar';

import SummaryView from './views/SummaryView';

const SummaryRouter = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* Routes that will not show navigation bar
        * Example:
        <Route exact path={path} component={Dashboard} /> 
        */}

      {/* Routes that will show navigation bar */}
      <Route path={path}>
        <KNavBar path={path} />
        <Route component={SummaryView} exact path={path} />
      </Route>
    </Switch>
  );
};

export default SummaryRouter;
