import React from 'react';
import { Box, Fade, Grid, Popper } from '@mui/material';

interface KFiltersPopperProps {
  anchorEl: HTMLElement | null;
  children?: React.ReactChild;
  open: boolean;
}

function FiltersPopper({ anchorEl, children, open }: KFiltersPopperProps) {
  return (
    <Box
      className='popperContainer'
      style={{ position: 'relative', width: '100%' }}
    >
      <Popper
        anchorEl={anchorEl}
        disablePortal
        open={open}
        placement='bottom-end'
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                mainAxis: false,
              },
            },
          ],
          strategy: 'absolute',
        }}
        role='menu'
        style={{ width: '100%', zIndex: 1000 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Grid container justifyContent='flex-end'>
              <Grid item md={8} xs={10}>
                {children}
              </Grid>
            </Grid>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default FiltersPopper;
