import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TablePagination from '@mui/material/TablePagination';

import styles from 'components/KTablePagination/KTablePagination.module.scss';
import KTablePaginationActions from 'components/KTablePaginationActions';

interface KTablePaginationProps {
  count: number;
  itemType?: string;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: Array<number | { label: string; value: number }>;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface LabelDisplayedRowsProps {
  count: number;
  from: number;
  to: number;
}

function KTablePagination({
  count,
  itemType = 'Item(s)',
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
}: KTablePaginationProps) {
  const buildLabel = ({ from, to, count }: LabelDisplayedRowsProps) =>
    `Showing ${from}-${to} of ${count} ${itemType}`;

  return (
    <TablePagination
      ActionsComponent={KTablePaginationActions}
      SelectProps={{
        classes: { icon: styles.iconSvg },
        IconComponent: ExpandMoreIcon,
        inputProps: {
          className: styles.selectInput,
        },
        MenuProps: {
          classes: { list: styles.menuItems },
        },
      }}
      className={styles.selectPagination}
      component='div'
      count={count}
      labelDisplayedRows={buildLabel}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
    />
  );
}

export default KTablePagination;
