import { KpiToSaveModel } from 'models/kpiToSaveModel';

import { AppThunk } from '../../app/store';
import { describeApiError } from '../../utils/errorUtils';
import { setToast } from '../toast/toastSlice';

import { fetchAssignmentData, updateAssignmentKpi } from './assignments.api';
import {
  setAssignmentHasError,
  setAssignmentKpis,
  setCompletionPercentage,
  setEvaluateeName,
  setIsLoadingAssignment,
} from './assignments.slice';

export const getAssignment =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setAssignmentHasError(false));
    dispatch(setIsLoadingAssignment(true));
    try {
      const { data } = await fetchAssignmentData(id);
      const { completionPercentage, employeeToBeReviewed, kpis } = data;
      dispatch(setAssignmentKpis(kpis));
      dispatch(setCompletionPercentage(completionPercentage));
      dispatch(setEvaluateeName(employeeToBeReviewed));
    } catch (error) {
      dispatch(setAssignmentHasError(true));
      dispatch(
        setToast({
          message: 'Assignment Not Found',
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingAssignment(false));
    }
  };

export const updateKpi =
  (assignmentId: number, kpiToSaveProps: KpiToSaveModel): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoadingAssignment(true));
    try {
      const { data } = await updateAssignmentKpi(assignmentId, kpiToSaveProps);
      const { completionPercentage, kpis } = data;
      dispatch(setAssignmentKpis(kpis));
      dispatch(setCompletionPercentage(completionPercentage));
    } catch (error) {
      dispatch(
        setToast({
          message: describeApiError(error),
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingAssignment(false));
    }
  };
