import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getAssignment,
  updateKpi,
} from 'features/assignments/assignments.actions';
import {
  selectAssignmentHasError,
  selectAssignmentKpis,
  selectCompletionPercentage,
  selectEvaluateeName,
  selectIsLoadingAssignment,
  selectKpiToSave,
} from 'features/assignments/assignments.selectors';
import { setKpiToSave } from 'features/assignments/assignments.slice';
import { Toast } from 'features/toast/Toast';
import { KpiToSaveModel } from 'models/kpiToSaveModel';

import KEvaluationProgressBar from 'components/KEvaluationProgressBar';
import KInstructionsCard from 'components/KInstructionsCard';
import KLayout from 'components/KLayout';
import KModal from 'components/KModal';
import KpiCard from 'components/KpiCard';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import {
  getKpiPosition,
  getModalContent,
  KpiPositionValues,
  ModalTypes,
} from 'modules/Evaluations/views/EvaluationFormView/evaluations.utils';

function EvaluationFormView(): ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id: assignmentId } = useParams<{ id: string }>();

  const assignmentError = useAppSelector(selectAssignmentHasError);
  const assignmentKpis = useAppSelector(selectAssignmentKpis);
  const completitionPercentage = useAppSelector(selectCompletionPercentage);
  const evaluateeName = useAppSelector(selectEvaluateeName);
  const isLoading = useAppSelector(selectIsLoadingAssignment);
  const kpiToSave = useAppSelector(selectKpiToSave);

  const [currentKpiIndex, setCurrentKpiIndex] = useState(0);
  const [currentKpi, setCurrentKpi] = useState(assignmentKpis[currentKpiIndex]);
  const [modalContent, setModalContent] = useState<Record<string, string>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const position = getKpiPosition(currentKpiIndex, assignmentKpis);

  useEffect(() => {
    setCurrentKpi(assignmentKpis[currentKpiIndex]);
  }, [assignmentKpis, currentKpiIndex]);

  useEffect(() => {
    dispatch(getAssignment(+assignmentId));
  }, [dispatch, assignmentId]);

  // Progressbar Action Handler
  const handleClickBack = () => {
    setModalContent(getModalContent(ModalTypes.BACK));
    setIsModalOpen(true);
  };

  // KPI Card Action Handlers
  const handleClickNextKpi = (kpiToSaveProps: KpiToSaveModel) => {
    dispatch(setKpiToSave(kpiToSaveProps));

    dispatch(updateKpi(+assignmentId, kpiToSaveProps));

    if (position === KpiPositionValues.LAST) {
      setModalContent(getModalContent(ModalTypes.SUBMIT));
      return setIsModalOpen(true);
    }

    return setCurrentKpiIndex(currentKpiIndex + 1);
  };

  const handleClickPreviousKpi = () => {
    setCurrentKpiIndex(currentKpiIndex - 1);
  };

  // Modal Action Handlers
  const handleClickLeave = () => {
    setIsModalOpen(false);
    history.push('/evaluations');
  };

  const handleClickSubmit = () => {
    // Final action
    dispatch(
      updateKpi(+assignmentId, { ...kpiToSave, completeEvaluation: true }),
    );

    setIsModalOpen(false);
    history.push({
      pathname: '/evaluations',
      state: { evaluatee: evaluateeName },
    });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  if (isLoading && !evaluateeName) {
    return (
      <Fragment>
        <KEvaluationProgressBar
          label='Back'
          progress={completitionPercentage}
          onClickBack={handleClickBack}
        />
        <KLayout>
          <LoadingSpinner />
        </KLayout>
      </Fragment>
    );
  }

  if (assignmentError) {
    return <Toast />;
  }

  return (
    <Fragment>
      <KEvaluationProgressBar
        label='Back'
        progress={completitionPercentage}
        onClickBack={handleClickBack}
      />
      <KLayout>
        <Grid
          columnSpacing={24}
          container
          justifyContent='center'
          rowGap={32}
          sx={{ paddingTop: '12px' }}
        >
          <Grid item xs={10}>
            <KInstructionsCard employeeToBeReviewed={evaluateeName} />
          </Grid>
          <Grid item xs={10}>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <KpiCard
                kpi={currentKpi}
                position={position}
                onClickBack={handleClickPreviousKpi}
                onClickNext={handleClickNextKpi}
              />
            )}
          </Grid>
        </Grid>
        <KModal
          actionLabel={modalContent.action}
          body={modalContent.body}
          open={isModalOpen}
          title={modalContent.title}
          onClickActionButton={
            modalContent.action === 'Submit'
              ? handleClickSubmit
              : handleClickLeave
          }
          onCloseModal={handleClose}
        />
      </KLayout>
    </Fragment>
  );
}

export default EvaluationFormView;
