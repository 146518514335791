import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { KpiDetailsData } from 'models/kpiDetails.model';
import { v4 as uuid } from 'uuid';

import KpiComment from 'components/KpiComment';

import styles from './KpiResultsDetails.module.scss';

function KpiResultsDetails({ description, scores }: KpiDetailsData) {
  return (
    <Box className={styles.rootContainer}>
      <Box className={styles.evaluatorsContainer}>
        {scores.map(({ score, maxScore }, index) => (
          <Box className={styles.scoreContainer} key={`score-${uuid()}`}>
            <Typography
              className={styles.evaluator}
              component='h5'
              variant='body1'
            >
              Evaluator {index + 1}
            </Typography>
            <Typography
              className={styles.kpiScore}
              component='h5'
              variant='subtitle1'
            >
              {score}/{maxScore}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography className={styles.description} component='p' variant='body1'>
        {description}
      </Typography>
      <Divider className={styles.divider} />
      {scores.map(({ comment }, index) =>
        comment ? (
          <KpiComment
            comment={comment}
            evaluator={index + 1}
            key={`comment-${uuid()}`}
          />
        ) : (
          ''
        ),
      )}
    </Box>
  );
}

export default KpiResultsDetails;
