import { PeriodProps } from 'models/periodsModel';

import { periods } from 'modules/Results/views/ResultsView/mockData';
import { ListResponseData } from 'services/baseService';

// A mock function to mimic making an async request for data
export function fetchPeriods() {
  const data = periods;
  const count = 0;
  const limit = 0;
  const offset = 0;
  return new Promise<ListResponseData<PeriodProps>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
          count,
          limit,
          offset,
        }),
      500,
    ),
  );
}
