import { MainTableColumn } from 'utils/table.utils';

export interface EvaluatorData {
  avatar: string;
  evaluatorList: string[];
  id: number;
  toBeEvaluated: string;
}

export interface KpiPoints {
  points: number;
  title: string;
}

export interface Department {
  id: number;
  name: string;
}

export interface Options {
  label: string;
  value: number | string;
}

export const mainTableHeaderColumns: MainTableColumn[] = [
  {
    field: 'toBeEvaluated',
    headerName: 'To be evaluated',
    hasSorting: true,
  },
  {
    field: 'evaluatorList',
    headerName: 'Evaluator list',
    hasSorting: true,
  },
  {
    field: 'link',
  },
];
