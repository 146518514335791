import React from 'react';
import { Box, Typography } from '@mui/material';

import textStyles from '../KTableCell/KTableCell.module.scss';

interface ListCellProps {
  list: string[];
  size?: string;
}

function ListCell({ list, size = '700' }: ListCellProps) {
  return (
    <Box sx={{ minWidth: `${size}px` }}>
      <Typography
        className={textStyles.tableCellText}
        component='p'
        variant='body1'
      >
        {list.join(', ')}
      </Typography>
    </Box>
  );
}

export default ListCell;
