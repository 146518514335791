import { QueryParamsModel } from 'models/queryParamsModel';

enum PeriodParams {
  HISTORICAL = 'historical',
  LAST_SIX_MONTHS = 'lastSixMonths',
  LAST_YEAR = 'lastYear',
}

enum StatusParams {
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  IN_PROGRESS = 'in_progress',
  NEW = 'new',
}

export const EVALUATIONS_FILTERS_SECTIONS = {
  status: {
    label: 'Status',
    options: [
      { label: 'New', param: 'new' },
      { label: 'In progress', param: 'in_progress' },
      { label: 'Expired', param: 'expired' },
      { label: 'Completed', param: 'completed' },
    ],
  },
  period: {
    label: 'Period',
    options: [
      { label: 'Last evaluated', param: 'lastEvaluated' },
      { label: 'Last six months', param: 'lastSixMonths' },
      { label: 'Last year', param: 'lastYear' },
      { label: 'Historical', param: 'historical' },
    ],
  },
};

export function prepareFilterParams(filters: string[]): QueryParamsModel {
  const period = filters.filter((filter) =>
    Object.values(PeriodParams).includes(filter as PeriodParams),
  );
  const status = filters.filter((filter) =>
    Object.values(StatusParams).includes(filter as StatusParams),
  );
  return filters.includes('lastEvaluated')
    ? { lastEvaluated: true, period, status }
    : { period, status };
}
