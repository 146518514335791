export const EVALUATORS_ASSIGNATION_FILTERS_SECTIONS = {
  evaluatorsAssignation: {
    label: 'Evaluators Assignation',
    options: [
      { label: 'From 1 to 3', param: 'from_1_to_3' },
      { label: 'From 4 to 6', param: 'from_4_to_6' },
      { label: 'From 7 to 10', param: 'from_7_to_10' },
      { label: 'More than 10', param: 'more_than_10' },
    ],
  },
  evaluatorsPosition: {
    label: 'Evaluators Position',
    options: [{ label: 'Software Engineer', param: '1' }],
  },
};
