import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KpiDetailsData } from 'models/kpiDetails.model';

interface KpiDetailsState {
  isLoadingKpiDetails: boolean;
  kpiDetails: KpiDetailsData[];
}

const initialState: KpiDetailsState = {
  isLoadingKpiDetails: false,
  kpiDetails: [],
};

export const kpiDetailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    setIsLoadingKpiDetails: (state, action: PayloadAction<boolean>) => {
      state.isLoadingKpiDetails = action.payload;
    },
    setKpiDetails: (state, action: PayloadAction<KpiDetailsData[]>) => {
      state.kpiDetails = action.payload;
    },
  },
});

export const { setIsLoadingKpiDetails, setKpiDetails } =
  kpiDetailsSlice.actions;

export const kpiDetailsReducer = kpiDetailsSlice.reducer;
