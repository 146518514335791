import React from 'react';
import { Box, Button, ButtonBase, Card, Divider, Grid } from '@mui/material';

import styles from './FiltersCard.module.scss';

interface KFiltersCardProps {
  children: React.ReactChild[] | React.ReactChild;
  onClickApply: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickClear: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function FiltersCard({
  children,
  onClickApply,
  onClickClear,
}: KFiltersCardProps) {
  return (
    <Card className={styles.cardRoot} elevation={1}>
      <Box>{children}</Box>
      <Box>
        <Divider sx={{ marginBottom: '16px' }} />
        <Grid
          alignItems='center'
          columnSpacing={24}
          columns={8}
          container
          justifyContent='space-between'
        >
          <Grid item sm={2} xs={4}>
            <ButtonBase
              className={styles.clearFilterButton}
              disableRipple
              onClick={onClickClear}
            >
              Clear filter(s)
            </ButtonBase>
          </Grid>
          <Grid container item justifyContent='flex-end' sm={2} xs={4}>
            <Button
              color='primary'
              sx={{ maxWidth: '97px' }}
              variant='contained'
              onClick={onClickApply}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
export default FiltersCard;
