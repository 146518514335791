export enum CardType {
  TopRated = 'Top rated',
  WorstRated = 'Worst rated',
}

export interface KResultCardProps {
  isLastItem?: boolean;
  text: string;
  value: string;
}
