import React, { useState } from 'react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Box,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { clone } from 'ramda';

import { MainTableColumn, SortingOptions } from 'utils/table.utils';

import styles from './KTableHeader.module.scss';

interface KTableHeaderProps {
  columns: MainTableColumn[];
  sortingOptions?: SortingOptions;
  onClickSort: (sortingValues: SortingOptions) => void;
}

function KTableHeader({
  columns,
  sortingOptions,
  onClickSort,
}: KTableHeaderProps) {
  const [sortingField, setSortingField] = useState('');

  const handleClickSort = (field: string) => () => {
    if (!sortingOptions || sortingOptions.length === 0) {
      onClickSort([[field, 'ASC']]);
      setSortingField(field);
      return;
    }

    const sortingIndex = sortingOptions.findIndex(
      ([currentField]) => currentField === field,
    );

    const sortingOptionsUpdated = clone(sortingOptions);
    if (sortingIndex >= 0 && sortingOptions[sortingIndex][1] === 'ASC') {
      sortingOptionsUpdated[sortingIndex][1] = 'DESC';
      onClickSort(sortingOptionsUpdated);
      return;
    }

    sortingOptionsUpdated.splice(sortingIndex, 1);

    onClickSort(sortingOptionsUpdated);
    setSortingField('');
  };

  const getSortIcon = (field: string) => {
    if (sortingOptions) {
      const sortValue = sortingOptions.find(
        ([currentField]) => currentField === field,
      );
      if (sortValue && sortValue[1] === 'DESC') {
        return <ArrowDownwardOutlinedIcon fontSize='small' />;
      }

      if (sortValue && sortValue[1] === 'ASC') {
        return <ArrowUpwardOutlinedIcon fontSize='small' />;
      }
    }
    return <ArrowUpwardOutlinedIcon fontSize='small' />;
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.field}>
            {!column.hasSorting && (
              <Typography
                className={styles.title}
                component='h5'
                variant='subtitle1'
              >
                {column.headerName}
              </Typography>
            )}
            {column.hasSorting && (
              <Box
                alignItems='center'
                className={
                  sortingField === column.field
                    ? styles.sortingContainer
                    : styles.headerCellContainer
                }
                display='flex'
                onClick={handleClickSort(column.field)}
              >
                <Typography
                  className={styles.title}
                  component='h5'
                  variant='subtitle1'
                >
                  {column.headerName}
                </Typography>
                <IconButton className={styles.iconButton}>
                  {getSortIcon(column.field)}
                </IconButton>
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default KTableHeader;
