import { fetchResultsSummary } from 'services/summaryService';

import { AppThunk } from '../../app/store';
import { describeApiError } from '../../utils/errorUtils';
import { setToast } from '../toast/toastSlice';

import { setIsLoadingSummary, setKpis, setQuarterTotal } from './summary.slice';

export const getSummary =
  (quarter: string, year: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoadingSummary(true));
    try {
      const { data } = await fetchResultsSummary(quarter, year);
      const { roundedAveragePercentage, kpis } = data;
      dispatch(setQuarterTotal(roundedAveragePercentage));
      dispatch(setKpis(kpis));
    } catch (error) {
      dispatch(
        setToast({
          message: describeApiError(error),
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingSummary(false));
    }
  };
