import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

import styles from 'components/KSearchBar/KSearchBar.module.scss';

interface KSearchBarProps {
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function KSearchBar({
  placeholder = 'Input text',
  value,
  onChange,
}: KSearchBarProps) {
  return (
    <Paper className={styles.paperRoot}>
      <IconButton aria-label='search' className={styles.iconButton}>
        <SearchIcon className={styles.iconSvg} />
      </IconButton>
      <InputBase
        className={styles.inputBaseRoot}
        inputProps={{
          'aria-label': 'search',
          className: styles.inputBaseInput,
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Paper>
  );
}

export default KSearchBar;
