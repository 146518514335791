import React, { Fragment } from 'react';
import { Typography } from '@mui/material';

import KpiToBeEvaluated from '../KpiToBeEvaluated/KpiToBeEvaluated';

import { KpiPoints } from './kFormKpisGroup.utils';

import styles from './KFormKpisGroup.module.scss';

interface KFormKpisGroupProps {
  emptySubtitle?: string;
  isContentEmpty: boolean;
  kpiPoints: KpiPoints[];
  title: string;
  totalPoints: KpiPoints[];
  onClickCheckbox: (kpi: KpiPoints, checked: boolean) => void;
}

function KFormKpisGroup({
  title,
  emptySubtitle = '',
  isContentEmpty,
  kpiPoints,
  totalPoints,
  onClickCheckbox,
}: KFormKpisGroupProps) {
  return (
    <Fragment>
      <Typography className={styles.instruction} component='p' variant='body1'>
        {title}
      </Typography>
      {isContentEmpty ? (
        <Typography
          className={styles.emptySubtitle}
          component='h2'
          variant='h4'
        >
          {emptySubtitle}
        </Typography>
      ) : (
        <KpiToBeEvaluated
          kpis={kpiPoints}
          totalPoints={totalPoints}
          onClickCheckbox={onClickCheckbox}
        />
      )}
    </Fragment>
  );
}

export default KFormKpisGroup;
