import React from 'react';
import Container from '@mui/material/Container';

import styles from './KLayout.module.scss';

interface KLayoutProps {
  children: React.ReactNode;
}

function KLayout({ children }: KLayoutProps) {
  return (
    <main className={styles.layoutMain}>
      <Container className={styles.layout} disableGutters maxWidth='lg'>
        {children}
      </Container>
    </main>
  );
}

export default KLayout;
