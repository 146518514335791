import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Container, IconButton, Typography } from '@mui/material';

import style from './KBackBar.module.scss';

export interface KBackBarProps {
  label: string;
  onClickBack: (event: React.MouseEvent<HTMLElement>) => void;
}

function KBackBar({ label, onClickBack }: KBackBarProps) {
  return (
    <Container className={style.container}>
      <Box className={style.box}>
        <IconButton className={style.iconButton} onClick={onClickBack}>
          <ArrowBackIcon className={style.iconSvg} />
        </IconButton>
        <Typography className={style.heading} component='h1' variant='h5'>
          {label}
        </Typography>
      </Box>
    </Container>
  );
}

export default KBackBar;
