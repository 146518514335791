import React from 'react';
import { Link } from 'react-router-dom';
import {
  KeyboardArrowDown as ArrowDownIcon,
  NotificationsOutlined as NotificationsIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Badge,
  Box,
  Container,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';

import styles from './KNavBar.module.scss';

interface KNavBarProps {
  path: string;
}

const navTabs = [
  {
    label: 'Summary',
    route: '/',
    value: 'summary',
  },
  {
    label: 'Evaluations',
    route: '/evaluations',
    value: 'evaluations',
  },
  {
    label: 'Results',
    route: '/results',
    value: 'results',
  },
];

const currentTab = (path: string): string => {
  const [_rest, route] = path.split('/');
  const currentTab = navTabs.find((tab) => tab.value === route);

  if (currentTab) {
    return currentTab.value;
  }

  return navTabs[0].value;
};

function KNavBar({ path }: KNavBarProps) {
  return (
    <AppBar elevation={0} position='static' sx={{ bgcolor: 'inherit' }}>
      <Toolbar disableGutters>
        <Container className={styles.appBarContainer} maxWidth='lg'>
          <Typography className={styles.appTitle} component='h3' variant='h3'>
            Performance app
          </Typography>
          <Box className={styles.navBarActionsContainer} sx={{ gap: '40px' }}>
            <Box
              className={styles.navLinksContainer}
              sx={{ marginRight: '16px' }}
            >
              <Tabs value={currentTab(path)}>
                {navTabs.map((tab) => (
                  <Tab
                    component={Link}
                    key={tab.value}
                    label={
                      <Typography component='p' variant='body1'>
                        {tab.label}
                      </Typography>
                    }
                    to={tab.route}
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </Box>
            <Box className={styles.menuButtonsContainer}>
              <IconButton
                className={styles.notificationsButton}
                sx={{ marginRight: '24px' }}
              >
                <Badge
                  className={styles.badge}
                  color='error'
                  overlap='circular'
                  variant='dot'
                >
                  <NotificationsIcon color='primary' fontSize='large' />
                </Badge>
              </IconButton>
              <IconButton className={styles.userButton}>
                <ArrowDownIcon color='primary' fontSize='large' />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default KNavBar;
