import React, { ReactElement } from 'react';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Avatar, Box, Card, Link, Typography } from '@mui/material';

import styles from './KProfile.module.scss';

interface KProfileProps {
  careerPathLink: string;
  country: string;
  department: string;
  jobPosition: string;
  name: string;
  profileImage: string;
}

const KProfile = ({
  careerPathLink,
  country,
  department,
  jobPosition,
  name,
  profileImage,
}: KProfileProps): ReactElement => (
  <Card className={styles.rootCard}>
    <Avatar alt={name} className={styles.avatar} src={profileImage} />
    <Box className={styles.textBox}>
      <Typography component='p' variant='subtitle1'>
        {name}
      </Typography>
      <Typography component='p' variant='body1'>
        {department}
      </Typography>
      <Link href={careerPathLink}>
        <SubdirectoryArrowRightIcon fontSize='inherit' /> {jobPosition}
      </Link>
      <Typography component='p' variant='body1'>
        {country}
      </Typography>
    </Box>
  </Card>
);

export default KProfile;
