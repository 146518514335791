import React, { ReactElement } from 'react';
import { Chip, Typography } from '@mui/material';

import styles from './KChip.module.scss';

interface KChipProps {
  isRankingDepartments?: boolean;
  position: string;
}

function KChip({ isRankingDepartments, position }: KChipProps): ReactElement {
  if (isRankingDepartments) {
    return (
      <Chip
        classes={{
          root: position === 'top' ? styles.topRated : styles.bottomRated,
          label: styles.label,
        }}
        label={
          <Typography variant='caption'>
            {position === 'top' ? 'Top' : 'Worst'} department
          </Typography>
        }
      />
    );
  }

  return (
    <Chip
      classes={{
        root: position === 'top' ? styles.topRated : styles.bottomRated,
        label: styles.label,
      }}
      label={
        <Typography variant='caption'>
          {position === 'top' ? 'Top' : 'Worst'} rated
        </Typography>
      }
    />
  );
}

export default KChip;
