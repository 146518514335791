import { PendingEvaluationsModel } from 'models/pendingEvaluationsModel';
import { KpiSummaryModel } from 'models/summaryModel';
import { UpcomingEvaluationModel } from 'models/upcomingEvaluationModel';
import { ProfileModel } from 'models/userProfileModel';

import {
  endDate,
  kpis,
  personalCount,
  profiles,
  startDate,
  teammateCount,
} from 'modules/Summary/views/SummaryView/mockData';
import { ResponseData } from 'services/baseService';

export function fetchProfileData(id: number) {
  const data = profiles[id];
  return new Promise<ResponseData<ProfileModel>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
        }),
      500,
    ),
  );
}

export function fetchUpcomingEvaluation() {
  const data = { startDate, endDate };
  return new Promise<ResponseData<UpcomingEvaluationModel>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
        }),
      500,
    ),
  );
}

export function fetchPendingEvaluations() {
  const data = { personalCount, teammateCount };
  return new Promise<ResponseData<PendingEvaluationsModel>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
        }),
      500,
    ),
  );
}

export function fetchResultsSummary(quarter: string, year: number) {
  const data = {
    roundedAveragePercentage: 100,
    quarter,
    year,
    kpis,
  };
  return new Promise<ResponseData<KpiSummaryModel>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
        }),
      500,
    ),
  );
}
