import { fetchProfileData } from 'services/summaryService';

import { AppThunk } from '../../app/store';
import { describeApiError } from '../../utils/errorUtils';
import { setToast } from '../toast/toastSlice';

import { setIsLoadingProfile, setProfile } from './userProfile.slice';

export const getProfileData =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoadingProfile(true));
    try {
      const { data } = await fetchProfileData(id);
      dispatch(setProfile(data));
    } catch (error) {
      dispatch(
        setToast({
          message: describeApiError(error),
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingProfile(false));
    }
  };
