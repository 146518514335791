import { EvaluatorData, KpiPoints, Options } from './newEvaluationForm.utils';

const periods = ['Q1', 'Q2', 'Q3', 'Q4'];

const kpiPoints: KpiPoints[] = [
  {
    points: 20,
    title: 'Lever of Prepareness/ Courses & Certifications',
  },
  {
    points: 35,
    title: 'Responsive time against threaths ',
  },
  {
    points: 30,
    title: 'Vulnerability Management Analysis ',
  },
  {
    points: 40,
    title: 'Reporting',
  },
];

const dropDownData: Options[] = [
  {
    label: 'Human Resources',
    value: 1,
  },
  {
    label: 'IT & OPS',
    value: 2,
  },
  {
    label: 'Cybersecurity ',
    value: 3,
  },
  {
    label: 'Recruitment ',
    value: 4,
  },
];

const evaluators: EvaluatorData[] = [
  {
    id: 1,
    avatar:
      'https://statics.memondo.com/p/99/gifs/2016/05/GIF_296217_0770cb57ae9a45a8b354369842193ef4_monos_que_se_han_acostumbrado_demasiado_a_la_buena_vida_thumb.jpg?cb=667678',
    toBeEvaluated: 'Daniel Alcudia',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 2,
    avatar:
      'https://st2.depositphotos.com/1594920/8612/i/950/depositphotos_86122502-stock-photo-close-up-of-a-young.jpg',
    toBeEvaluated: 'Sergio Serrallonga',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 3,
    avatar: 'https://i.ytimg.com/vi/dOKurtZnmAk/hqdefault.jpg',
    toBeEvaluated: 'Rafa Rodríguez',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 4,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    toBeEvaluated: 'Liz Rodríguez',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 5,
    avatar: 'https://pbs.twimg.com/media/Eud18OPVoAIWf5S.png',
    toBeEvaluated: 'John Orozco',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 6,
    avatar:
      'https://statics.memondo.com/p/99/gifs/2016/05/GIF_296217_0770cb57ae9a45a8b354369842193ef4_monos_que_se_han_acostumbrado_demasiado_a_la_buena_vida_thumb.jpg?cb=667678',
    toBeEvaluated: 'Daniel Alcudia',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 7,
    avatar:
      'https://st2.depositphotos.com/1594920/8612/i/950/depositphotos_86122502-stock-photo-close-up-of-a-young.jpg',
    toBeEvaluated: 'Sergio Serrallonga',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 8,
    avatar: 'https://i.ytimg.com/vi/dOKurtZnmAk/hqdefault.jpg',
    toBeEvaluated: 'Rafa Rodríguez',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 9,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    toBeEvaluated: 'Liz Rodríguez',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 10,
    avatar: 'https://pbs.twimg.com/media/Eud18OPVoAIWf5S.png',
    toBeEvaluated: 'John Orozco',
    evaluatorList: ['Unassigned'],
  },
  {
    id: 11,
    avatar: 'https://pbs.twimg.com/media/Eud18OPVoAIWf5S.png',
    toBeEvaluated: 'John Orozco',
    evaluatorList: ['Unassigned'],
  },
];

export { dropDownData, kpiPoints, evaluators, periods };
