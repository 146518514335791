import { KpiModel } from 'models/kpiModel';

export enum KpiPositionValues {
  FIRST = 'first',
  MIDDLE = 'middle',
  LAST = 'last',
}

export enum KpiCardButtonLabels {
  NEXT = 'Next',
  SUBMIT = 'Submit',
}

export enum ModalTypes {
  BACK = 'back',
  SUBMIT = 'submit',
}

const BACK_MODAL_CONTENT = {
  action: 'Leave',
  body: 'Your progress will be saved.\nYou can complete the evaluation later.',
  title: 'Leave Evaluation',
};

const SUBMIT_MODAL_CONTENT = {
  action: 'Submit',
  body: 'Thanks for your participation. Once you submit the evaluation, you will no longer be able to edit the form.',
  title: 'Submit Evaluation',
};

export function generateKpiOptions(
  range: Record<string, number>,
): Array<number> {
  const { endValue, increment, startValue } = range;

  return Array.from(
    { length: (endValue - startValue) / increment + 1 },
    (_element, index) => startValue + index * increment,
  );
}

export function getButtonLabel(position: string) {
  if (position === KpiPositionValues.LAST) {
    return KpiCardButtonLabels.SUBMIT;
  }
  return KpiCardButtonLabels.NEXT;
}

export function getKpiPosition(index: number, arr: Array<KpiModel>) {
  switch (index) {
    case 0:
      return KpiPositionValues.FIRST;
    case arr.length - 1:
      return KpiPositionValues.LAST;
    default:
      return KpiPositionValues.MIDDLE;
  }
}

export function getModalContent(type: string): Record<string, string> {
  switch (type) {
    case ModalTypes.BACK:
      return BACK_MODAL_CONTENT;
    case ModalTypes.SUBMIT:
      return SUBMIT_MODAL_CONTENT;
    default:
      return {};
  }
}

/* The following function 'calculates' the completion progress, there may be a better way to do it 
but since this logic will be managed by the BE, it will be removed later anyways */
export function getCompletionPercentage(
  kpiList: Array<KpiModel>,
  currentKpiIndex: number,
): number {
  const totalKpis = kpiList.length;
  return Math.floor(((currentKpiIndex + 1) * 100) / totalKpis);
}
