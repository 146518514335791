import { KpiDetailsData } from 'models/kpiDetails.model';

import { kpiDetails } from 'modules/Results/views/ResultsView/mockData';
import { ListResponseData } from 'services/baseService';

// A mock function to mimic making an async request for data
export function fetchKpiDetailsByPeriod(
  periodRequested: string,
  yearRequested: number,
) {
  const data = kpiDetails.filter(
    ({ period, year }) =>
      period[1] === periodRequested && year === yearRequested,
  );
  const count = 0;
  const limit = 0;
  const offset = 0;
  return new Promise<ListResponseData<KpiDetailsData>>((resolve) =>
    setTimeout(
      () =>
        resolve({
          message: 'OK',
          data,
          count,
          limit,
          offset,
        }),
      500,
    ),
  );
}
