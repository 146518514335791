import React, { Fragment } from 'react';
import { Divider, Grid, Typography } from '@mui/material';

import { CardType, KResultCardProps } from './resultCardUtils';

import styles from './KResultCard.module.scss';

function getTitleClass(text: string) {
  switch (text) {
    case CardType.TopRated:
      return styles.topRatedTitle;
    case CardType.WorstRated:
      return styles.worstRatedTitle;
    default:
      return styles.baseTitle;
  }
}

function KResultCard({ text, value, isLastItem }: KResultCardProps) {
  return (
    <Fragment>
      <Grid
        item
        md
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
        xs
      >
        <Typography
          className={getTitleClass(text)}
          component='h2'
          sx={{ mb: 8 }}
          variant={
            text === CardType.TopRated || text === CardType.WorstRated
              ? 'h4'
              : 'h3'
          }
        >
          {value}
        </Typography>
        <Typography
          className={styles.baseSubtitle}
          component='p'
          variant='body1'
        >
          {text}
        </Typography>
      </Grid>
      {!isLastItem && (
        <Divider
          orientation='vertical'
          sx={{ height: '64px', margin: 'auto', borderRightWidth: 1 }}
        />
      )}
    </Fragment>
  );
}

export default KResultCard;
