import React, { useState } from 'react';
import { Divider, SelectChangeEvent } from '@mui/material';
import { FilterSectionsModel } from 'models/filtersSectionsModel';

import FiltersCard from '../components/Card';
import CheckboxFilters from '../components/Sections/CheckboxFilters';
import DropdownFilters from '../components/Sections/DropdownFilters';

interface EvaluatorsAssignationFiltersProps {
  sections: Record<string, FilterSectionsModel>;
  onClickApplyFilters: (queryParams: {
    evaluatorsAssignation: string[];
    evaluatorsPosition: string;
  }) => void;
}

function EvaluatorsAssignationFilters({
  sections,
  onClickApplyFilters,
}: EvaluatorsAssignationFiltersProps) {
  const { evaluatorsPosition, evaluatorsAssignation } = sections;
  const [evaluatorsPositionFilters, setEvaluatorsPositionFilters] =
    useState<string>('');
  const [evaluatorsAssignationFilters, setEvaluatorsAssignationFilters] =
    useState<string[]>([]);

  const handleChangeEvaluatorsAssignationFilters = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (evaluatorsAssignationFilters.includes(event.target.name)) {
      const newFiltersValue = evaluatorsAssignationFilters.filter(
        (evaluatorsAssignation) => evaluatorsAssignation !== event.target.name,
      );
      return setEvaluatorsAssignationFilters(newFiltersValue);
    }

    return setEvaluatorsAssignationFilters([
      ...evaluatorsAssignationFilters,
      event.target.name,
    ]);
  };

  const handleSelectEvaluatorsPositionFilter = (event: SelectChangeEvent) => {
    setEvaluatorsPositionFilters(event.target.value);
  };

  const handleClearFilters = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setEvaluatorsAssignationFilters([]);
    setEvaluatorsPositionFilters('');
  };

  const handleClickApply = (_event: React.MouseEvent<HTMLButtonElement>) => {
    const queryParams = {
      evaluatorsPosition: evaluatorsPositionFilters,
      evaluatorsAssignation: [...evaluatorsAssignationFilters],
    };
    onClickApplyFilters(queryParams);
  };

  return (
    <FiltersCard
      onClickApply={handleClickApply}
      onClickClear={handleClearFilters}
    >
      <DropdownFilters
        section={evaluatorsPosition}
        value={evaluatorsPositionFilters}
        onSelect={handleSelectEvaluatorsPositionFilter}
      />
      <Divider sx={{ marginBottom: '24px' }} />
      <CheckboxFilters
        filters={evaluatorsAssignationFilters}
        section={evaluatorsAssignation}
        onChange={handleChangeEvaluatorsAssignationFilters}
      />
    </FiltersCard>
  );
}

export default EvaluatorsAssignationFilters;
