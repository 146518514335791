import React, { Fragment } from 'react';
import { Typography } from '@mui/material';

import KComboBox from '../KComboBox/KComboBox';

import { Options } from './kFromDropdownGroup.utils';

import styles from './KFormDropdownGroup.module.scss';

interface KFormDropdownGroupProps {
  dropdownOptions: Options[];
  title: string;
  onSelectValue: (option: string) => void;
}

function KFormDropdownGroup({
  title,
  dropdownOptions,
  onSelectValue,
}: KFormDropdownGroupProps) {
  return (
    <Fragment>
      <Typography className={styles.instruction} component='p' variant='body1'>
        {title}
      </Typography>
      <KComboBox
        label='Department'
        options={dropdownOptions}
        onSelect={onSelectValue}
      />
    </Fragment>
  );
}

export default KFormDropdownGroup;
