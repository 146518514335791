import { RootState } from 'app/store';

export const selectAssignmentHasError = (state: RootState) =>
  state.assignment.assignmentHasError;
export const selectAssignmentKpis = (state: RootState) =>
  state.assignment.assignmentKpis;
export const selectCompletionPercentage = (state: RootState) =>
  state.assignment.completionPercentage;
export const selectEvaluateeName = (state: RootState) =>
  state.assignment.evaluateeName;
export const selectIsLoadingAssignment = (state: RootState) =>
  state.assignment.isLoadingAssignment;
export const selectKpiToSave = (state: RootState) => state.assignment.kpiToSave;
