import { AppThunk } from 'app/store';

import { describeApiError } from 'utils/errorUtils';

import { setToast } from '../toast/toastSlice';

import { setIsLoadingPeriods, setPeriods } from './period.slice';
import { fetchPeriods } from './periods.api';

export const getQuarters = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoadingPeriods(true));
  try {
    const { data } = await fetchPeriods();
    dispatch(setPeriods(data));
  } catch (error) {
    dispatch(
      setToast({
        message: describeApiError(error),
        isActive: true,
        type: 'error',
      }),
    );
  } finally {
    dispatch(setIsLoadingPeriods(false));
  }
};
