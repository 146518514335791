import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileModel } from 'models/userProfileModel';

interface UserProfileState {
  isLoadingProfile: boolean;
  profile: ProfileModel;
}

const initialState: UserProfileState = {
  isLoadingProfile: true,
  profile: {
    careerPathLink: '',
    country: '',
    department: '',
    id: 0,
    jobPosition: '',
    name: '',
    profileImage: '',
  },
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setIsLoadingProfile: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProfile = action.payload;
    },
    setProfile: (state, action: PayloadAction<ProfileModel>) => {
      state.profile = action.payload;
    },
  },
});

export const { setIsLoadingProfile, setProfile } = userProfileSlice.actions;

export const userProfileReducer = userProfileSlice.reducer;
