import React, { Fragment, ReactElement, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Button, Card, Grid, Typography } from '@mui/material';

import emptyStateImg from 'assets/images/emptyState.svg';
import KBackBar from 'components/KBackBar';
import KEmptyState from 'components/KEmptyState';
import FiltersPopper from 'components/KFiltersCard/components/Popper/FiltersPopper';
import NewEvaluationFormFilters, {
  NEW_EVALUATION_FORM_FILTERS_SECTIONS,
} from 'components/KFiltersCard/NewEvaluationFormFilters';
import KFormDateGroup from 'components/KFormDateGroup';
import KFormDropdownGroup from 'components/KFormDropdownGroup';
import KFormKpisGroup from 'components/KFormKpisGroup';
import KFormRadioGroup from 'components/KFormRadioGroup';
import KFormSearchGroup from 'components/KFormSearchGroup';
import KLayout from 'components/KLayout';
import KTable from 'components/KTable';
import AvatarCell from 'components/KTable/components/AvatarCell';
import LinkCell from 'components/KTable/components/LinkCell';
import ListCell from 'components/KTable/components/ListCell';
import {
  DEFAULT_ROWS_PER_PAGE,
  rowsPerPageOptions,
} from 'components/KTable/constants';
import KTablePagination from 'components/KTablePagination';
import { MainTableCell, MainTableRow, SortingOptions } from 'utils/table.utils';

import { dropDownData, evaluators, kpiPoints, periods } from './mockData';
import {
  EvaluatorData,
  KpiPoints,
  mainTableHeaderColumns,
} from './newEvaluationForm.utils';

import styles from './NewEvaluationForm.module.scss';

function NewEvaluationFormView(): ReactElement {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [totalPoints, setTotalPoins] = useState<KpiPoints[]>([]);
  const [tableData] = useState(evaluators);
  const [period, setPeriod] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [department, setDepartment] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [sortingOptions, setSortingOptions] = useState<SortingOptions>([]);
  const [offset, setOffset] = useState(0);
  const [filtersAnchor, setFiltersAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  const handleClickCheckbox = (kpi: KpiPoints, checked: boolean) =>
    checked
      ? setTotalPoins((prev) => [...prev, kpi])
      : setTotalPoins((prev) =>
          prev.filter(
            (current) =>
              current.points !== kpi.points && current.title !== kpi.title,
          ),
        );

  const handleClickOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPeriod = event.target.value;
    setPeriod(newPeriod);
  };

  const handleClickSorting = (sortingValues: SortingOptions) => {
    setSortingOptions(sortingValues);
  };

  const handleClickSave = () => {
    // TODO: add logic to save new evaluation when clicking "Save"
  };

  const handleClickNext = () => {
    // TODO: add logic to handle the behavior when clicking "Next"
  };

  const handleClickSubmit = () => {
    // TODO: add logic to submit a new evaluation when clicking "Submit"
  };

  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchor(event.currentTarget);
    setAreFiltersOpen(!areFiltersOpen);
  };

  const handleClickApplyFilters = (queryParams: {
    evaluators: string[];
    evaluatorsAssigned: string[];
  }) => {
    // TODO: add logic to handle queryParams when filtering
    //  dispatch(setQueryParams(queryParams));
    setAreFiltersOpen(!areFiltersOpen);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (newPage > page) {
      setOffset(offset + (newPage - page) * rowsPerPage);
    } else {
      setOffset(offset - (page - newPage) * rowsPerPage);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const handleChangeStartDate = (selectedDate: Date | null) => {
    setStartDate(selectedDate);
  };

  const handleChangeEndDate = (selectedDate: Date | null) => {
    setEndDate(selectedDate);
  };

  const handleSelectDepartment = (option: string) => {
    setDepartment(option);
  };

  const getRows = (tableData: EvaluatorData[]): MainTableRow[] =>
    tableData.map((evaluator: EvaluatorData) => {
      const { avatar, toBeEvaluated, id, evaluatorList } = evaluator;
      const toBeEvaluatedCell: MainTableCell = {
        value: <AvatarCell avatarURL={avatar} text={toBeEvaluated} />,
      };
      const evaluatorListCell = {
        value: <ListCell list={evaluatorList} />,
      };
      const linkCell = { value: <LinkCell to={`${url}/${id}`} /> };

      const innerCells: MainTableCell[] = [
        toBeEvaluatedCell,
        evaluatorListCell,
        linkCell,
      ];

      return {
        id,
        innerCells,
      };
    });

  return (
    <Fragment>
      <KBackBar label='Back' onClickBack={handleClickBack} />
      <KLayout>
        <FiltersPopper anchorEl={filtersAnchor} open={areFiltersOpen}>
          <NewEvaluationFormFilters
            sections={NEW_EVALUATION_FORM_FILTERS_SECTIONS}
            onClickApplyFilters={handleClickApplyFilters}
          />
        </FiltersPopper>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography
              className={styles.orginalPath}
              component='p'
              sx={{ marginBottom: '16px' }}
              variant='body2'
            >
              Company Evaluations&gt;
            </Typography>
            <Typography
              component='p'
              sx={{ marginLeft: '4px' }}
              variant='body2'
            >
              New Evaluation
            </Typography>
          </Box>
          <Typography component='h1' variant='h5'>
            New Evaluation
          </Typography>
        </Box>
        <Card className={styles.rootCard} elevation={0}>
          <Grid columnSpacing='10px' columns={12} container rowSpacing='32px'>
            <Grid item xs={12}>
              <Typography component='h2' variant='subtitle1'>
                Set up the new evaluation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <KFormRadioGroup
                radioLabels={periods}
                radioValue={period}
                title='1. Select the corresponding evaluation period'
                onChangeRadio={handleClickOption}
              />
            </Grid>
            <Grid item xs={6}>
              <KFormDateGroup
                endDate={endDate}
                startDate={startDate}
                title='2. Select a start and end date'
                onChangeEndDate={handleChangeEndDate}
                onChangeStartDate={handleChangeStartDate}
              />
            </Grid>
            <Grid className={styles.itemDropdown} item xs={6}>
              <KFormDropdownGroup
                dropdownOptions={dropDownData}
                title='3. Select the department(s) to evaluate'
                onSelectValue={handleSelectDepartment}
              />
            </Grid>
            <Grid item xs={12}>
              <KFormKpisGroup
                emptySubtitle='No departments selected yet.'
                isContentEmpty={!department}
                kpiPoints={kpiPoints}
                title='4. Select de KPIs to be evaluated for this group'
                totalPoints={totalPoints}
                onClickCheckbox={handleClickCheckbox}
              />
            </Grid>
            <Grid item xs={12}>
              <KFormSearchGroup
                filterTextButton='Filter'
                isFilterButtonDisabled={!department}
                searchValue={searchValue}
                title='5. Teammates selected for the evaluation.'
                onChangeSearch={handleChangeSearch}
                onClickFilter={handleClickFilters}
              />
            </Grid>
            <Grid item xs={12}>
              {!department ? (
                <KEmptyState
                  image={
                    <img
                      alt='emptyState'
                      height={324}
                      src={emptyStateImg}
                      width={324}
                    />
                  }
                  title='Oops! There are not selected groups yet.'
                />
              ) : (
                <Fragment>
                  <KTable
                    columns={mainTableHeaderColumns}
                    isLoading={false}
                    noRowsMessage='Teammates not found'
                    rows={getRows(tableData)}
                    sortingOptions={sortingOptions}
                    onClickSort={handleClickSorting}
                  />
                  <Box sx={{ marginTop: '32px' }}>
                    <KTablePagination
                      count={tableData.length}
                      itemType='evaluation(s)'
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Box>
                </Fragment>
              )}
            </Grid>
            <Grid
              item
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              xs={12}
            >
              <Grid item sx={{ marginRight: '24px' }} xs={2}>
                <Button
                  disabled={!department}
                  variant='outlined'
                  onClick={handleClickSave}
                >
                  Save as Draft
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled
                  variant='contained'
                  onClick={!department ? handleClickNext : handleClickSubmit}
                >
                  {!department ? 'Next' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </KLayout>
    </Fragment>
  );
}

export default NewEvaluationFormView;
