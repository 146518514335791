const profiles = [
  {
    id: 1,
    careerPathLink: 'https://thispersondoesnotexist.com/image',
    country: 'Mexico',
    department: 'Product Design',
    jobPosition: 'Product Designer I',
    name: 'Travis Sheridan',
    profileImage: 'https://thispersondoesnotexist.com/image',
  },
  {
    id: 2,
    careerPathLink: 'https://thispersondoesnotexist.com/image',
    country: 'Dominican Republic',
    department: 'Product Design',
    jobPosition: 'Product Designer I',
    name: 'Travis Sheridan',
    profileImage: 'https://thispersondoesnotexist.com/image',
  },
];

const startDate = '21/04/2022';
const endDate = '15/07/2022';

const teammateCount = 2;
const personalCount = 1;

const departmentTotal = 94;
const companyTotal = 83;

const kpis = [
  {
    kpiId: 1,
    maxScore: 45,
    kpiName: 'Level of preparedness/courses & certifications',
    group: 'top',
    myScore: 45,
  },
  {
    kpiId: 2,
    maxScore: 45,
    kpiName: 'Response time against threaths',
    group: 'top',
    myScore: 45,
  },
  {
    kpiId: 3,
    maxScore: 45,
    kpiName: 'Vulnerability management analysis',
    group: 'average',
    myScore: 30,
  },
  {
    kpiId: 4,
    maxScore: 45,
    kpiName: 'Reporting',
    group: 'bottom',
    myScore: 15,
  },
];

const departmentKpis = [
  {
    kpiId: 3,
    maxScore: 165,
    kpiName: 'Level of preparedness/courses & certifications',
    group: 'top',
    myScore: 160,
  },
  {
    kpiId: 4,
    maxScore: 165,
    kpiName: 'Vulnerability management analysis',
    group: 'bottom',
    myScore: 100,
  },
];

const companyRanking = [
  {
    kpiId: 3,
    maxScore: 165,
    kpiName: 'Human Resources',
    group: 'top',
    myScore: 160,
  },
  {
    kpiId: 4,
    maxScore: 165,
    kpiName: 'Salesforce',
    group: 'bottom',
    myScore: 100,
  },
];

export {
  companyRanking,
  companyTotal,
  departmentKpis,
  departmentTotal,
  endDate,
  personalCount,
  startDate,
  teammateCount,
  kpis,
  profiles,
};
