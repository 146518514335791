import { MainTableColumn } from 'utils/table.utils';

export interface EvaluatorData {
  assigned: number;
  commonProject: string[];
  id: number;
  name: string;
  position: string;
}

export const mainTableHeaderColumns: MainTableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    hasSorting: true,
  },
  {
    field: 'position',
    headerName: 'Position',
    hasSorting: true,
  },
  {
    field: 'commonProject',
    headerName: 'Common Project',
    hasSorting: true,
  },
  {
    field: 'assigned',
    headerName: 'Assigned',
    hasSorting: true,
  },
];
