import React from 'react';
import { TableCell, Typography } from '@mui/material';

import { MainTableCell } from 'utils/table.utils';

import styles from './KTableCell.module.scss';

interface KTableCellProps {
  cellData: MainTableCell;
}

function KTableCell({ cellData }: KTableCellProps) {
  const cellContent = (value: string | React.ReactElement) => {
    if (typeof value === 'string') {
      return (
        <Typography
          className={styles.tableCellText}
          component='p'
          variant='body1'
        >
          {value}
        </Typography>
      );
    }

    return value;
  };

  return (
    <TableCell className={styles.tableCell}>
      {cellContent(cellData.value)}
    </TableCell>
  );
}

export default KTableCell;
