import { AssignmentDataModel } from 'models/assignmentDataModel';
import { KpiToSaveModel } from 'models/kpiToSaveModel';

import { getCompletionPercentage } from 'modules/Evaluations/views/EvaluationFormView/evaluations.utils';
import { assignments } from 'modules/Evaluations/views/EvaluationFormView/mockData';
import { ResponseData } from 'services/baseService';

// Fake API GET operation to retrieve all the evaluation assignment data
const fetchAssignmentData = (assignmentId: number) => {
  const selectedAssignment = assignments[assignmentId];

  return new Promise<ResponseData<AssignmentDataModel>>((resolve, reject) => {
    setTimeout(() => {
      if (selectedAssignment) {
        return resolve({ message: 'OK', data: selectedAssignment });
      }
      return reject(new Error('Assignment not found'));
    }, 400);
  });
};

const updateAssignmentKpi = async (
  assignmentId: number,
  kpiToSaveProps: KpiToSaveModel,
) => {
  const { comment, selectedKpiId, score } = kpiToSaveProps;
  const {
    data: { kpis },
  } = await fetchAssignmentData(assignmentId);
  const kpiToUpdateIndex = kpis.findIndex(
    (kpi) => kpi.selectedKpiId === selectedKpiId,
  );

  return new Promise<ResponseData<AssignmentDataModel>>((resolve, reject) => {
    setTimeout(() => {
      if (kpiToUpdateIndex !== -1) {
        const progress = getCompletionPercentage(kpis, kpiToUpdateIndex);
        const updatedKpis = [...kpis];

        updatedKpis[kpiToUpdateIndex] = {
          ...updatedKpis[kpiToUpdateIndex],
          comment,
          kpiScore: score,
          reactives: {
            ...updatedKpis[kpiToUpdateIndex].reactives,
            selectedAnswer: score,
          },
        };

        assignments[assignmentId] = {
          ...assignments[assignmentId],
          completionPercentage: progress,
          kpis: [...updatedKpis],
        };

        return resolve({ message: 'OK', data: assignments[assignmentId] });
      }
      return reject(new Error('Kpi Not Found'));
    }, 200);
  });
};

export { fetchAssignmentData, updateAssignmentKpi };
