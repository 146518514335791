import React, { ReactElement } from 'react';
import { Card, Typography } from '@mui/material';

import styles from './KQuarterTotal.module.scss';

interface KQuarterTotalProps {
  quarter?: string;
  total: string | number;
  totalLabel?: string;
  totalType?: 'quarter' | 'department' | 'company';
  year?: number;
}

const KQuarterTotal = ({
  quarter,
  total,
  totalLabel = 'Total',
  totalType = 'quarter',
  year,
}: KQuarterTotalProps): ReactElement => (
  <Card
    className={
      {
        quarter: styles.totalCard,
        department: styles.departmentTotalCard,
        company: styles.companyTotalCard,
      }[totalType]
    }
  >
    <Typography color='primary' component='p' variant='h3'>
      {total}%
    </Typography>
    <Typography component='p' variant='subtitle2'>
      {totalLabel}
      <br />
      {quarter} {year}
    </Typography>
  </Card>
);

export default KQuarterTotal;
