import React, { Fragment, ReactElement } from 'react';
import StarIcon from '@mui/icons-material/Star';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Typography } from '@mui/material';

interface KpiRankingProps {
  maxScore: number | string;
  position: string;
  score: number | string;
}

const KpiRanking = ({
  maxScore,
  position,
  score,
}: KpiRankingProps): ReactElement => {
  switch (position) {
    case 'top':
      return (
        <Fragment>
          <StarIcon color='success' />
          <Typography color='success.main' component='p' variant='h4'>
            {score}/{maxScore}
          </Typography>
        </Fragment>
      );
    case 'bottom':
      return (
        <Fragment>
          <WarningRoundedIcon color='warning' />
          <Typography color='warning.main' component='p' variant='h4'>
            {score}/{maxScore}
          </Typography>
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <Typography component='p' marginLeft={40} variant='h4'>
            {score}/{maxScore}
          </Typography>
        </Fragment>
      );
  }
};
export default KpiRanking;
