const evaluations = [
  {
    id: 1,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 2,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'APR-2021-JUN-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 3,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JUL-2021-SEP-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 4,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'OCT-2021-DEC-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 5,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 6,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 7,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 8,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 9,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 10,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 11,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 12,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 13,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 14,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 15,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 16,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 17,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 18,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 19,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 20,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 21,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 22,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 23,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 24,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 25,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 26,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 27,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 28,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 29,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 30,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 31,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 32,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 33,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 34,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 35,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 36,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 37,
    avatar:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmbEO33eCA6BBWpUod1Q-bb6A271e3U2rUIGSifsNUmCVXsxkZtdCjtIiXN97XbN_-BQ&usqp=CAU',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'In progress',
    toBeEvaluated: 'Balbina Sóley',
  },
  {
    id: 38,
    avatar:
      'https://www.laguiadelvaron.com/wp-content/uploads/2017/09/Captura-48.png',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'New',
    toBeEvaluated: 'Tase Norbert',
  },
  {
    id: 39,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Expired',
    toBeEvaluated: 'Anne Sharif',
  },
  {
    id: 40,
    avatar: 'https://i.ytimg.com/vi/4rv0G6p2jZ4/sddefault.jpg#404_is_fine',
    dueDate: '2022-02-01T05:00:00.000Z',
    period: 'JAN-2021-MAR-2021',
    project: 'Project Name',
    status: 'Completed',
    toBeEvaluated: 'Anne Sharif',
  },
];

export { evaluations };
