import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';
import { FilterSectionsModel } from 'models/filtersSectionsModel';

import styles from './CheckboxFilters.module.scss';

interface CheckboxFiltersProps {
  filters: string[];
  section: FilterSectionsModel;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxFilters = ({
  filters,
  section,
  onChange,
}: CheckboxFiltersProps) => {
  const { label, options } = section;

  return (
    <FormControl>
      <Grid columns={8} container>
        <Grid item md={2} xs={8}>
          <FormLabel>
            <Typography
              component='legend'
              marginBottom='12px'
              paddingRight='16px'
              variant='subtitle1'
            >
              {label}
            </Typography>
          </FormLabel>
        </Grid>
        <Grid item md={6} xs={8}>
          <FormGroup sx={{ marginBottom: '24px' }}>
            <Grid columns={6} container rowGap={14}>
              {options.map((option) => {
                const { label, param } = option;
                return (
                  <Grid item key={label} md={2} xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters?.includes(param)}
                          className={styles.filterCheckBox}
                          name={param}
                          onChange={onChange}
                        />
                      }
                      label={label}
                      sx={{ margin: '0' }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default CheckboxFilters;
