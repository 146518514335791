import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpcomingEvaluationModel } from 'models/upcomingEvaluationModel';

interface UpcomingEvaluationState {
  isLoadingUpcomingEvaluation: boolean;
  upcomingEvaluationDate: UpcomingEvaluationModel;
}

const initialState: UpcomingEvaluationState = {
  isLoadingUpcomingEvaluation: true,
  upcomingEvaluationDate: {
    startDate: '',
    endDate: '',
  },
};

export const upcomingEvaluationSlice = createSlice({
  name: 'upcomingEvaluation',
  initialState,
  reducers: {
    setUpcomingEvaluationDate: (
      state,
      action: PayloadAction<UpcomingEvaluationModel>,
    ) => {
      state.upcomingEvaluationDate = action.payload;
    },
    setIsLoadingUpcomingEvaluation: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpcomingEvaluation = action.payload;
    },
  },
});

export const { setUpcomingEvaluationDate, setIsLoadingUpcomingEvaluation } =
  upcomingEvaluationSlice.actions;

export const upcomingEvaluationReducer = upcomingEvaluationSlice.reducer;
