import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import styles from './KDropdown.module.scss';

export interface Options {
  label: string;
  value: number | string;
}

interface KDropdownProps {
  initialValue?: string;
  label?: string;
  options: Array<Options>;
  onSelect: (option: string) => void;
}

export default function KDropdown({
  initialValue = '',
  label,
  options,
  onSelect,
}: KDropdownProps) {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  React.useEffect(() => {
    onSelect(value);
  }, [onSelect, value]);

  return (
    <TextField
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        MenuProps: {
          anchorOrigin: {
            vertical: 56,
            horizontal: 80,
          },
        },
      }}
      className={!label ? styles.noLabel : ''}
      fullWidth
      label={label}
      select
      value={value}
      onChange={handleChange}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
