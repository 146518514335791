import React, { Fragment } from 'react';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import styles from './KFormRadioGroup.module.scss';

interface KFormRadioGroupProps {
  radioLabels: string[];
  radioValue: string;
  title: string;
  onChangeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function KFormRadioGroup({
  title,
  radioValue,
  radioLabels,
  onChangeRadio,
}: KFormRadioGroupProps) {
  return (
    <Fragment>
      <Typography className={styles.instruction} component='p' variant='body1'>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'space-between',
        }}
      >
        <RadioGroup
          aria-labelledby='kpiOptions'
          className={styles.radioButtonsContainer}
          name={radioValue}
          value={radioValue}
          onChange={onChangeRadio}
        >
          {radioLabels.map((label) => (
            <FormControlLabel
              className={styles.radioButtonLabel}
              control={
                <Radio
                  className={styles.radioButton}
                  color='primary'
                  size='medium'
                />
              }
              key={label}
              label={label}
              sx={{ margin: '0' }}
              value={label}
            />
          ))}
        </RadioGroup>
      </Box>
    </Fragment>
  );
}

export default KFormRadioGroup;
