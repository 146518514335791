const getPixelNumber = (pixelStringValue: string) => {
  const [size] = pixelStringValue.split('px');
  return size ? parseInt(size, 10) : 0;
};

/**
 *
 * @param value Pixel value. Could be an integer like `24` or string like `"24px"`
 * @returns `rem` value resulting from dividing the input value and root `font-size` (`16px`)
 */
export const pixelToRem = (pixelValue: number | string) => {
  if (typeof pixelValue === 'string') {
    const size = getPixelNumber(pixelValue);
    return `${size / 16}rem`;
  }

  return `${pixelValue / 16}rem`;
};

/**
 *
 * @param options an object that representes the pixel font size (`fontSizePx`) and the line height unit (`lineHeightPx`)
 * @returns Line height proportion
 */
export const getLineHeight = (options: {
  fontSizePx: number | string;
  lineHeightPx: number | string;
}) => {
  const { fontSizePx, lineHeightPx } = options;

  const fontSize =
    typeof fontSizePx === 'string' ? getPixelNumber(fontSizePx) : fontSizePx;

  const lineHeight =
    typeof lineHeightPx === 'string'
      ? getPixelNumber(lineHeightPx)
      : lineHeightPx;

  return `${lineHeight / fontSize}`;
};

/**
 *
 * @param spacingPercentage Letter spacing percentage to convert
 * @returns `em` value resulting from dividing input value and `100`
 */
export const getLetterSpacing = (spacingPercentage: number) =>
  `${spacingPercentage / 100}em`;
