import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KpiModel } from 'models/summaryModel';

interface EvaluationState {
  isLoadingSummary: boolean;
  kpis: KpiModel[];
  quarter: string;
  quarterTotal: string | number;
  year: number;
}

const initialState: EvaluationState = {
  isLoadingSummary: true,
  kpis: [],
  quarter: '',
  quarterTotal: 0,
  year: 0,
};

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setIsLoadingSummary: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSummary = action.payload;
    },
    setQuarter: (state, action: PayloadAction<string>) => {
      state.quarter = action.payload;
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
    setQuarterTotal: (state, action: PayloadAction<number>) => {
      state.quarterTotal = action.payload;
    },
    setKpis: (state, action: PayloadAction<KpiModel[]>) => {
      state.kpis = action.payload;
    },
  },
});

export const {
  setIsLoadingSummary,
  setKpis,
  setQuarter,
  setQuarterTotal,
  setYear,
} = summarySlice.actions;

export const summaryReducer = summarySlice.reducer;
