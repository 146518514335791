import React, { useState } from 'react';
import { Divider } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { selectQueryParams } from 'features/evaluations/evaluation.selectors';
import { FilterSectionsModel } from 'models/filtersSectionsModel';
import { QueryParamsModel } from 'models/queryParamsModel';

import FiltersCard from '../components/Card';
import CheckboxFilters from '../components/Sections/CheckboxFilters';

import { prepareFilterParams } from './EvaluationsFilters.utils';

interface EvaluationsFiltersProps {
  sections: Record<string, FilterSectionsModel>;
  onClickApplyFilters: (queryParams: QueryParamsModel) => void;
}

function EvaluationsFilters({
  sections,
  onClickApplyFilters,
}: EvaluationsFiltersProps) {
  const { status: statusSection, period: periodSection } = sections;
  const { lastEvaluated, period, status } = useAppSelector(selectQueryParams);
  const [filters, setFilters] = useState<string[]>([
    lastEvaluated ? 'lastEvaluated' : '',
    ...period,
    ...status,
  ]);

  const handleChangeFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (filters.includes(event.target.name)) {
      const newFiltersValue = filters.filter(
        (filter) => filter !== event.target.name,
      );
      return setFilters(newFiltersValue);
    }

    return setFilters([...filters, event.target.name]);
  };

  const handleClearFilters = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setFilters([]);
  };

  const handleClickApply = (_event: React.MouseEvent<HTMLButtonElement>) => {
    const params = prepareFilterParams(filters);
    onClickApplyFilters(params);
  };

  return (
    <FiltersCard
      onClickApply={handleClickApply}
      onClickClear={handleClearFilters}
    >
      <CheckboxFilters
        filters={filters}
        section={statusSection}
        onChange={handleChangeFilters}
      />
      <Divider sx={{ marginBottom: '24px' }} />
      <CheckboxFilters
        filters={filters}
        section={periodSection}
        onChange={handleChangeFilters}
      />
    </FiltersCard>
  );
}

export default EvaluationsFilters;
