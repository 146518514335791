import { AssignmentDataModel } from 'models/assignmentDataModel';

// Pseudo DataBase
export const assignments: Record<number, AssignmentDataModel> = {
  1: {
    completionPercentage: 0,
    employeeEvaluationsAssignmentId: 1,
    evaluationPeriod: 'Q1',
    employeeToBeReviewed: 'Jane Doe',
    kpis: [
      {
        comment: '',
        departmentKpiId: 1,
        description: '',
        kpiName: 'KPI Name',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et.',
          reactiveId: 1,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 15,
          increment: 5,
          startValue: 5,
        },
        selectedKpiId: 1,
      },
      {
        comment: '',
        departmentKpiId: 2,
        description: '',
        kpiName: 'KPI Name 2',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Im the second KPI',
          reactiveId: 2,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 30,
          increment: 5,
          startValue: 20,
        },
        selectedKpiId: 2,
      },
      {
        comment: '',
        departmentKpiId: 3,
        description: '',
        kpiName: 'KPI Name 3',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Im the third KPI',
          reactiveId: 3,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 20,
          increment: 5,
          startValue: 10,
        },
        selectedKpiId: 3,
      },
    ],
  },
  2: {
    completionPercentage: 0,
    employeeEvaluationsAssignmentId: 2,
    evaluationPeriod: 'Q1',
    employeeToBeReviewed: 'John Doe',
    kpis: [
      {
        comment: '',
        departmentKpiId: 1,
        description: '',
        kpiName: 'KPI Name',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et.',
          reactiveId: 1,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 20,
          increment: 5,
          startValue: 10,
        },
        selectedKpiId: 1,
      },
      {
        comment: '',
        departmentKpiId: 2,
        description: '',
        kpiName: 'KPI Name 2',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Im the second KPI',
          reactiveId: 2,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 25,
          increment: 5,
          startValue: 15,
        },
        selectedKpiId: 2,
      },
      {
        comment: '',
        departmentKpiId: 3,
        description: '',
        kpiName: 'KPI Name 3',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Im the third KPI',
          reactiveId: 3,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 15,
          increment: 5,
          startValue: 5,
        },
        selectedKpiId: 3,
      },
    ],
  },
  3: {
    completionPercentage: 0,
    employeeEvaluationsAssignmentId: 3,
    evaluationPeriod: 'Q1',
    employeeToBeReviewed: 'Jim Foo',
    kpis: [
      {
        comment: '',
        departmentKpiId: 1,
        description: '',
        kpiName: 'KPI Name',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et.',
          reactiveId: 1,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 15,
          increment: 5,
          startValue: 5,
        },
        selectedKpiId: 1,
      },
      {
        comment: '',
        departmentKpiId: 2,
        description: '',
        kpiName: 'KPI Name 2',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Im the second KPI',
          reactiveId: 2,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 30,
          increment: 5,
          startValue: 20,
        },
        selectedKpiId: 2,
      },
      {
        comment: '',
        departmentKpiId: 3,
        description: '',
        kpiName: 'KPI Name 3',
        kpiScore: 0,
        reactives: {
          description: '',
          name: 'Im the third KPI',
          reactiveId: 3,
          selectedAnswer: null,
        },
        scoreRange: {
          endValue: 20,
          increment: 5,
          startValue: 10,
        },
        selectedKpiId: 3,
      },
    ],
  },
};
