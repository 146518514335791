import React from 'react';
import { Box, Typography } from '@mui/material';

import KReadMore from '../KReadMore/index';

import styles from './KpiComment.module.scss';

interface KpiCommentProps {
  comment: string;
  evaluator: string | number;
}

function KpiComment({ comment, evaluator }: KpiCommentProps) {
  return (
    <Box className={styles.evaluatorCommentContainer} key={evaluator}>
      <Typography className={styles.evaluator} component='h5' variant='body1'>
        Evaluator {evaluator}
      </Typography>
      <Box className={styles.commentContainer}>
        <KReadMore maxLength={200} text={comment} />
      </Box>
    </Box>
  );
}

export default KpiComment;
