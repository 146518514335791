import { AppThunk } from 'app/store';

import { describeApiError } from 'utils/errorUtils';

import { setToast } from '../toast/toastSlice';

import { fetchResume } from './resume.api';
import { setIsLoadingResume, setResume } from './resume.slice';

export const getResume =
  (periodRequested: string, yearRequested: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoadingResume(true));
    try {
      const { data } = await fetchResume(periodRequested, yearRequested);
      dispatch(setResume(data));
    } catch (error) {
      dispatch(
        setToast({
          message: describeApiError(error),
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingResume(false));
    }
  };
