import React, { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import KDatePicker from 'components/KDatePicker/KDatePicker';

import styles from './KFormDateGroup.module.scss';

interface KFormRadioGroupProps {
  endDate: Date | null;
  startDate: Date | null;
  title: string;
  onChangeEndDate: (selectedDate: Date | null) => void;
  onChangeStartDate: (selectedDate: Date | null) => void;
}

function KFormDateGroup({
  title,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}: KFormRadioGroupProps) {
  return (
    <Fragment>
      <Typography className={styles.instruction} component='p' variant='body1'>
        {title}
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Grid item sx={{ marginRight: '24px' }} xs={12}>
          <KDatePicker
            date={startDate}
            label='Start Date'
            onChangeDate={onChangeStartDate}
          />
        </Grid>
        <Grid item xs={12}>
          <KDatePicker
            date={endDate}
            label='End Date'
            onChangeDate={onChangeEndDate}
          />
        </Grid>
      </Box>
    </Fragment>
  );
}

export default KFormDateGroup;
