import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { clearToast, selectToast } from './toastSlice';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
));

export function Toast() {
  const toast = useSelector(selectToast);
  const dispatch = useDispatch();

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(clearToast());
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toast.isActive}
        onClose={handleClose}
      >
        <Alert
          severity={toast.type}
          sx={{ width: '100%' }}
          onClose={handleClose}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
