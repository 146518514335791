import React, { Fragment, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { getPendingEvaluationsCount } from 'features/pendingEvaluations/pendingEvaluations.actions';
import {
  selectIsLoadingPendingEvaluations,
  selectPendingEvaluationsCount,
} from 'features/pendingEvaluations/pendingEvaluations.selectors';
import { selectUserRole } from 'features/session/sessionSelectors';
import { setUserRole } from 'features/session/sessionSlice';
import { getSummary } from 'features/summary/summary.actions';
import {
  selectIsLoadingSummary,
  selectKpis,
  selectQuarter,
  selectQuarterTotal,
  selectYear,
} from 'features/summary/summary.selectors';
import { setQuarter, setYear } from 'features/summary/summary.slice';
import { getUpcomingEvaluation } from 'features/upcomingEvaluation/upcomingEvaluation.actions';
import {
  selectIsLoadingUpcomingEvaluation,
  selectUpcomingEvaluationDate,
} from 'features/upcomingEvaluation/upcomingEvaluation.selectors';
import { getProfileData } from 'features/userProfile/userProfile.actions';
import {
  selectIsLoadingProfile,
  selectProfile,
} from 'features/userProfile/userProfile.selectors';

import KEvaluationCounter from 'components/KEvaluationCounter';
import KLayout from 'components/KLayout';
import KPendingReminder from 'components/KPendingReminder';
import KProfile from 'components/KProfile';
import KQuarterTotal from 'components/KQuarterTotal';
import KSummaryKpis from 'components/KSummaryKpis';
import KUpcomingReminder from 'components/KUpcomingReminder';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { getCurrentQuarter, getCurrentYear } from 'utils/date.utils';

import {
  companyRanking,
  companyTotal,
  departmentKpis,
  departmentTotal,
} from './mockData';

function SummaryView(): ReactElement {
  const dispatch = useDispatch();

  const userRole = useSelector(selectUserRole);
  const {
    careerPathLink,
    country,
    department,
    jobPosition,
    name,
    profileImage,
  } = useSelector(selectProfile);
  const { startDate, endDate } = useSelector(selectUpcomingEvaluationDate);
  const kpis = useSelector(selectKpis);
  const { personalCount, teammateCount } = useSelector(
    selectPendingEvaluationsCount,
  );
  const quarter = useSelector(selectQuarter);
  const total = useSelector(selectQuarterTotal);
  const year = useSelector(selectYear);

  const isLoadingProfile = useSelector(selectIsLoadingProfile);
  const isLoadingUpcomingEvaluation = useSelector(
    selectIsLoadingUpcomingEvaluation,
  );
  const isLoadingPendingEvaluations = useSelector(
    selectIsLoadingPendingEvaluations,
  );
  const isLoadingSummary = useSelector(selectIsLoadingSummary);

  useEffect(() => {
    dispatch(setUserRole('hr'));
    dispatch(setQuarter(`Q${getCurrentQuarter()}`));
    dispatch(setYear(getCurrentYear()));
    dispatch(getProfileData(1));
    dispatch(getUpcomingEvaluation());
    dispatch(getPendingEvaluationsCount());
    dispatch(getSummary(quarter, year));
  }, [dispatch, quarter, year]);

  if (
    isLoadingProfile ||
    isLoadingSummary ||
    isLoadingPendingEvaluations ||
    isLoadingUpcomingEvaluation
  ) {
    return (
      <KLayout>
        <LoadingSpinner />
      </KLayout>
    );
  }

  return (
    <KLayout>
      <Typography component='h1' variant='h5'>
        Summary {quarter}
      </Typography>
      <Grid
        columnSpacing={24}
        container
        marginBottom={40}
        marginTop={0}
        rowSpacing={24}
      >
        <Grid
          display='flex'
          flexDirection='column'
          item
          md={3}
          rowGap={24}
          xs={12}
        >
          <KProfile
            careerPathLink={careerPathLink}
            country={country}
            department={department}
            jobPosition={jobPosition}
            name={name}
            profileImage={profileImage}
          />
          <KUpcomingReminder endDate={endDate} startDate={startDate} />
        </Grid>
        <Grid
          alignContent='flex-start'
          columnSpacing={24}
          container
          item
          md={9}
          rowSpacing={24}
          xs={12}
        >
          <Grid item md={4} xs={12}>
            <KQuarterTotal quarter={quarter} total={total} year={year} />
          </Grid>
          <Grid item md={8} xs={12}>
            <KPendingReminder
              personalCount={personalCount}
              teammateCount={teammateCount}
            />
          </Grid>
          <Grid item xs={12}>
            <KSummaryKpis kpis={kpis} />
          </Grid>
        </Grid>
      </Grid>
      {(userRole === 'hr' || userRole === 'manager') && (
        <Fragment>
          <Typography component='h1' variant='h5'>
            My department
          </Typography>
          <Grid
            columnSpacing={24}
            container
            marginBottom={40}
            marginTop={0}
            rowSpacing={24}
          >
            <Grid item md={3} rowGap={24} xs={12}>
              <KQuarterTotal
                total={departmentTotal}
                totalLabel='Department average'
                totalType='department'
              />
            </Grid>
            <Grid
              display='flex'
              flexDirection='column'
              item
              md={9}
              rowGap={24}
              xs={12}
            >
              <KEvaluationCounter completedCount={50} pendingCount={20} />
              <KSummaryKpis isTopTwo kpis={departmentKpis} />
            </Grid>
          </Grid>
        </Fragment>
      )}
      {userRole === 'hr' && (
        <Fragment>
          <Typography component='h1' variant='h5'>
            Company
          </Typography>
          <Grid columnSpacing={24} container marginTop={0} rowSpacing={24}>
            <Grid item md={3} rowGap={24} xs={12}>
              <KQuarterTotal
                total={companyTotal}
                totalLabel='Company average'
                totalType='company'
              />
            </Grid>
            <Grid
              display='flex'
              flexDirection='column'
              item
              md={9}
              rowGap={24}
              xs={12}
            >
              <KEvaluationCounter completedCount={100} pendingCount={150} />
              <KSummaryKpis
                isRankingDepartments
                isTopTwo
                kpis={companyRanking}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </KLayout>
  );
}

export default SummaryView;
