import { ServiceOptions } from 'services/baseService';

import { AppThunk } from '../../app/store';
import { describeApiError } from '../../utils/errorUtils';
import { setToast } from '../toast/toastSlice';

import {
  setCount,
  setEvaluations,
  setIsLoadingEvaluations,
} from './evaluation.slice';
import { fetchEvaluations } from './evaluations.api';

export const getEvaluations =
  (options: ServiceOptions): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoadingEvaluations(true));
    try {
      const { count, data } = await fetchEvaluations(options);
      dispatch(setCount(count));
      dispatch(setEvaluations(data));
    } catch (error) {
      dispatch(
        setToast({
          message: describeApiError(error),
          isActive: true,
          type: 'error',
        }),
      );
    } finally {
      dispatch(setIsLoadingEvaluations(false));
    }
  };
